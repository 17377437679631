@import '../../styles/variables.scss';

.evi-title-header {
  font-size: 36px;
}
.evi-titlecard
{
  flex-grow: 0;
  flex-shrink: 0;

  a, a:link, a:visited {
    color: inherit;
  }
}

.ea-video-thumbnail__description
{
  color: inherit;
}

.evi-titlecard--image
{
  display: block;
  position: relative;
  background-color: #e0e0e1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: $default-border-radius;
}

@media only screen and (min-width: 640px) and (max-width: 740px)
{
  .evi-title-button-video
  {
    font-size: 14px;
  }
}

.evi-title-cover-block
{
  min-height: 180px;
  background-color: #e0e0e1;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $default-border-radius;
}

@media only screen and (max-width: 639px) {
  .slick-slider .evi-title-play-icon {
    font-size: 60px;
  }
}
