@use "sass:math";
@import './variables';

@mixin for-phone-only {
  @media (max-width: $breakpoint-phone-max-width) {
    @content;
  }
}

@mixin for-phone-large-down {
  @media (max-width: $breakpoint-phone-large-max-width) {
    @content;
  }
}

@mixin for-phone-large-only {
  @media (min-width: $breakpoint-phone-large-min-width) and (max-width: $breakpoint-phone-large-max-width) {
    @content;
  }
}

@mixin for-phone-large-up {
  @media (min-width: $breakpoint-phone-large-min-width) {
    @content;
  }
}

@mixin for-tablet-down {
  @media (max-width: $breakpoint-tablet-max-width) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
    @content;
  }
}

@mixin for-tablet-up {
  @media (min-width: $breakpoint-tablet-min-width) {
    @content;
  }
}

@mixin for-laptop-down {
  @media (max-width: $breakpoint-laptop-max-width) {
    @content;
  }
}

@mixin for-laptop-only {
  @media (min-width: $breakpoint-laptop-small-min-width) and (max-width: $breakpoint-laptop-large-max-width) {
    @content;
  }
}

@mixin for-laptop-up {
  @media (min-width: $breakpoint-laptop-min-width) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: $breakpoint-desktop-min-width) and (max-width: $breakpoint-desktop-max-width) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $breakpoint-desktop-min-width) {
    @content;
  }
}

@mixin for-desktop-large-only {
  @media (min-width: $breakpoint-desktop-large-min-width) {
    @content;
  }
}

@mixin useTheme {
  color: var(--neutral700-color);
  background: var(--neutral-color);
  background-image: var(--background-image);
}

@mixin accessibility-enabled {
  body.accessibility-enabled & {
    @content;
  }
}

@mixin accessibility-not-enabled {
  body:not(.accessibility-enabled) & {
    @content;
  }
}

@mixin focus-styling {
  outline: auto;
  outline-color: rgb(77, 144, 254);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

@mixin focus-styling-border($border-width: 2px, $shadow: $color-white, $border: #4D90FE) {
  outline: none;
  -webkit-box-shadow: 0 0 0 1px $shadow;
  box-shadow: 0 0 0 1px $shadow;
  border: solid $border-width $border;
  border-color: $border !important;
}

// This mixin will calculate a fluid/responsive size between defined min- and max-size when inside defined lower- and upper-range
@mixin fluid-type($type: font-size, $min-size: 12px, $max-size: 21px, $lower-range: $breakpoint-phone-large-min-width, $upper-range: $breakpoint-desktop-min-width) {
  #{$type}: calc(#{$min-size} + #{(math.div($max-size, ($max-size * 0 + 1)) - math.div($min-size, ($min-size * 0 + 1)))} * math.div((100vw - #{$lower-range}), #{(math.div($upper-range, ($upper-range * 0 + 1)) - math.div($lower-range, ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    #{$type}: $min-size;
  }
  @media screen and (min-width: $upper-range){
    #{$type}: $max-size;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}
