.evi-add-card-readers {
  &__link {
    cursor: pointer;
  }

  &__help-text {
    font-size: 13px;
    line-height: 20px;
  }

  &__error {
    margin-top: 23px;
  }
}
