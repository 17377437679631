@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.evi-purchase-wizard-thank-you-step {
  padding: 0 50px;

  @include for-phone-large-down {
    padding: 0 25px;
  }

  &__heading {
    padding-bottom: 16px;
  }

  &__animation {
    text-align: center;
  }

  &__description {
    margin: 0 0 34px;
    padding: 0 0 29px;
    border-bottom: 1px solid $color-dark-smoke;
  }
}
