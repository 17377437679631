.evi-description-banner {
  width: 18.75%;
}

.evi-description-banner img {
  width: 100%;
  max-height: 100%;
  margin: 10px 0 0 0;
}

.evi-description-title {
  display: inline-block;
  width: 81.25%;
}

.evi-event-poster-player-wrapper {
  background: #000;
}

.evi-subtitle {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 639px) {
  .evi-description-title {
    display: inline-block;
    width: 81.25%;
  }
}
