@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.evi-lazy-watchable-stripe {
  &.use-theme {
    @include useTheme();
  }

  margin: 0 0 12px;
  overflow: hidden;

  @include accessibility-enabled {
    padding-bottom: 3px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px;
    padding: 12px 5% 0;
    position: relative;
  }

  &__title {
    margin: 0;
    padding: 0;
  }

  &:not(&--has-intersected) &__header &__title,
  &--is-loading &__header &__title {
    background: var(--neutral100-color);
    color: transparent !important;
  }

  &:not(&--has-intersected) &__header a,
  &--is-loading &__header a {
    display: none;
  }

  .evi-layout-wide & {
    overflow: visible;

    @include for-desktop-up {
      margin: 2vw 0;

      &__title {
        line-height: 1.25vw;
        font-size: 1.4vw;
        margin-bottom: 0.5em;
      }
    }
  }

  #viihdeonline-react-app-root & .ea-button:focus {
    @include accessibility-enabled {
      @include focus-styling-border();
    }
  }
}
