@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-simple-modal {
  color: $color-dark-grey;
  font-size: 16px;
  
  .ea-modal__content {
    background: #f5f5f5;
   
    svg {
      fill: $color-dark-grey;
    }
  }

  .ea-modal__overlay {
    background: rgba(0, 0, 0, .5);
    
  }

  .ea-modal__close {
    @include accessibility-not-enabled {
      outline: 0;
    }

    .ea-icon {
      display: block;
      line-height: 1.4;
    }

    .ea-modal__close-cross {
      color: var(--neutral500-color);
      color: var(--neutral500-color);
      font-size: 24px;
    }
  }
  .ea-h3 {
    padding: 0;
    margin-bottom: 16px;
  }

  .ea-modal__buttons {
    text-align: right;

    .ea-button--link {
      color: var(--highlight-color);
    }
  }

  @media only screen and (min-width: 640px) {
    .evi-modal__box__share {
      padding: 16px 0px 40px 24px;
    }
  }
}


