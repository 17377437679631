@import '../../../styles/mixins';
@import '../../../styles/variables';

@include keyframes(progressFill) {
  from { background-position: right bottom; }
  to   { background-position: left bottom; }
}

.evi-progress-bar-button {
  position: relative;
  overflow: hidden;
  border: none;
  padding: 12px 32px;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 50%, rgba(0,0,0,.2) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    animation-name: progressFill;
    animation-duration: inherit;
    animation-delay: -.1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: absolute;
    left: 0;
    top: 0;
  }
}