.evi-card-devices {
  &__add-card {
    margin-top: 23px;
  }

  &__help-text {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }

  &__limit-text {
    font-size: 13px;
    line-height: 20px;
  }
}
