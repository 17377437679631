@import '../../../../styles/variables';

.evi-embedded-activation-button {
    margin: 32px 0 16px 0;
    display: block;
}

@media only screen and (min-width: $breakpoint-laptop-small-min-width) {
    .evi-product-payment-options {
        overflow: visible;
    }
}