@import '../../../../styles/variables.scss';

.evi-channel-package-price-info {
  border-top: 1px solid $color-dark-smoke;
  padding: 28px 0 0 0;
  margin: 28px 0 0 0;

  &__price {
    border-bottom: 1px solid $color-dark-smoke;
  }

  &__channels,
  &__extra-channels {
    &-title {
      padding: 24px 0 8px 0;
    }

    ul {
      border-bottom: 1px solid $color-dark-smoke;
      padding: 0 0 24px;
      columns: 2;
    }

    li {
      font-size: 13px;
      line-height: 24px;
      color: #70707A;
    }
  }

  &__extra-channels {
    &-title {
      display: flex;
      width: 100%;

      > span {
        &:first-child {
          flex: 1;
        }
      }
    }

    &-price {
      font-weight: normal;
    }
  }

  &__total-price {
    display: flex;
    padding: 24px 0;

    &-label {
      flex: 1;
    }

    &-value {

    }

    &-text {
      margin: 0;
      padding: 0 0 3px;
      font-weight: normal;
    }
  }

  &__total-normal-price {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    color: #70707A;
    text-align: right;
  }
}
