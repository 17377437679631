@import '../../../styles/mixins.scss';

.evi-slider-button {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 5%;
  -webkit-appearance: none;
  border: none;
  outline: none;
  z-index: 3;
  padding: 0;

  &--previous {
    left: 0;
  }

  &--next {
    right: 0;
  }

  &__spinner {
    transform: scale(0.8);
  }

  &:hover > &__icon {
    transform: scale(1.25);
  }

  .evi-layout-wide & {
    &__icon {
      font-size: 1.9vw;
    }
  }
}
