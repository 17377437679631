@import '../../../styles/mixins.scss';

.evi-pin-code-payment-method {
  padding: 0 0 35px;

  &__underage-message {
    margin: 0 0 20px;
  }

  &__pin-code {
    h4 {
      padding: 0 0 20px;
    }
  }

  &__pin-code-lost-header {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &__button {
    @include for-phone-large-down {
      min-width: 100%;
    }
  }

  .evi-code-input {
    margin: 0 0 20px;
  }

  &__accordions {
    padding: 35px 0 0 0;
  }
}
