.evi-account-info {
  .ea-fgrid__item {
    overflow: visible;
  }
}

.my-order {
  &-main-product {
    border-top: 1px solid #d0d0d1;
    padding: 48px 24px 48px 24px;
    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &-icon {
    color: #006dfc;
    font-size: 24px;

    img {
      width: 30px;
      height: 30px;
    }

    &-large {
      font-size: 82px;
    }

    &-block {
      display: block;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 1rem;
    }
  }
  &-product-block {
      display: block;
      flex-basis: auto;
      flex-grow: 1;
      p
      {
        margin: 0 0 0 0;
      }
  }
  &-product-info
    {
      margin: 1rem 0 1rem 0;
    }

  &-icon-block-align-right
    {
      text-align: right;
    }

  &-product
    {
      padding: 24px 0 0 24px;
    }
  &-item {
      margin-bottom: 12px;
    }
  &-name
  {
    font-weight: 600;
  }
  &-delete
  {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: right;
    .ea-icon
    {
      cursor: pointer;
    }
  }
  &-input
  {
    width: 100%;
  }
  &-help-block
  {
    max-width: 60rem;
    #cardreaderhelp {
      text-align: left;
      margin-right: 20px
    }
    .ea-accordion__element
    {
      border-top: none;
      border-bottom: none;
    }
  }
  &-tooltip {
    visibility: visible;
    z-index: 100;
    max-width: 300px;
    .ea-button
    {
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 660px) {
  .my-order {
    &-icon-block {
      width: auto;
    }

    &-product {
      display: block;
      position: relative;
      padding-left: 0;
    }

    &-icon-block-align-right {
      text-align: left;
    }

    &-help-block,
    &-input {
      width: auto;
    }
  }
}

/* placeholders until pattern library version is updated */
.my-orders-container {
  .ea-input
  {
    flex-wrap: wrap;
  }

  .ea-accordion__header
  {
    display: flex;
  }

  .ea-accordion__header > * {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center
  }

  .ea-accordion--arrow-left .ea-accordion__header {
    margin-right: 0;
    margin-left: 50px
  }

  .ea-accordion--arrow-left .ea-accordion__header:before {
    right: auto;
    left: 16px
  }

  .ea-accordion--arrow-left .ea-accordion__content {
    padding-left: 50px;
  }

  .ea-context-notification {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 24px;
    text-align: left;
    background: #fafafa;
    padding: 30px 36px 30px;
    border: 1px solid #e0e0e1;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }

  @media only screen and (min-width: 640px) {
    .ea-context-notification {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }

  .ea-context-notification__icon {
    color: #b0b0b1;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding-top: 2px;
    padding-bottom: 18px;
  }

  @media only screen and (min-width: 640px) {
    .ea-context-notification__icon {
      padding-bottom: 0;
      padding-right: 20px;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
    }
  }

  .ea-context-notification__custom-icon {
    fill: #b0b0b1;
    width: 50px;
    height: 50px;
  }

  .ea-context-notification__close {
    position: absolute;
    cursor: pointer;
    color: #b0b0b1;
    top: 1.6rem;
    right: 1.6rem;
  }
}
