@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.evi-cast-stripe {
  @include useTheme();
  margin: 0 0 12px;
  overflow: hidden;

  @include accessibility-enabled {
    padding-bottom: 3px;
  }

  .evi-watchable-information-block & {
    background: transparent;

    .evi-slider__slides,
    .evi-stripe-skeleton__items {
      margin: 0 -4px;
    }
    
    .evi-stripe-item,
    .evi-stripe-skeleton__item {
      padding: 0 4px;
    }

    .evi-slider-button {
      background: transparent !important;

      &:hover {
        background-image: linear-gradient(to bottom, rgba(22, 22, 22, 0.5) 0%, rgba(22, 22, 22, 0.5) calc(100% - 46px), transparent calc(100% - 46px)) !important;
      }

      @include accessibility-enabled {
        &:focus {
          background-image: linear-gradient(to bottom, rgba(22, 22, 22, 0.5) 0%, rgba(22, 22, 22, 0.5) calc(100% - 46px), transparent calc(100% - 46px)) !important;
        }
      }

      &--next {
        margin-right: -8px;
      }

      &--previous {
        margin-left: -8px;
      }
    }
  }

  .evi-tab-bar & {
    transform: translateX(-5%);
    width: 106%;
  }

  .ea-snapper-fluid-container {
    max-width: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px;
    padding: 24px 5% 0;
    position: relative;
  }

  &__title {
    margin: 0;
    padding: 0;
  }

  &:not(&--has-intersected) &__header &__title,
  &--is-loading &__header &__title {
    background: var(--neutral100-color);
    color: transparent !important;
  }

  &:not(&--has-intersected) &__header a,
  &--is-loading &__header a {
    display: none;
  }

  .evi-layout-wide & {
    overflow: visible;

    @include for-desktop-up {
      margin: 2vw 0;

      &__title {
        line-height: 1.25vw;
        font-size: 1.4vw;
        margin-bottom: 0.5em;
      }
    }
  }

  #viihdeonline-react-app-root & .ea-button:focus {
    @include accessibility-enabled {
      @include focus-styling-border();
    }
  }
}
