@import '/src/styles/mixins.scss';
.pre-rex-container{
  max-width: 1500px;
}
.pre-rex-order-confirmation-container {
  width: 75%;
  margin-left: auto;
  margin-right: auto
}
.gift-icon-name {
  display: flex;
  
}
.offer-price-block {
  padding:0px;
}

/* Breakpoint for 479px and below */
@media (max-width: 479px) {
  .not-eligible-prerex {
    margin-left: 6%;
  } 
  .gift-icon-name {
    width:75%;
  }
  .offer-price-block {
    width:25%;
  }
}

/* Breakpoint for 480px to 639px */
@media (min-width: 480px) and (max-width: 639px) {
  .not-eligible-prerex {
    margin-left: 6%;
  }
  
  .evi-prerex-campaign-header {
    .prerex-campaign-name{
      flex-basis: 45%;
    }
  }
}

/* Breakpoint for 640px to 959px */
@media (min-width: 640px) and (max-width: 959px) {
  .not-eligible-prerex {
    margin-left: 5%;
  }
}

/* Breakpoint for 960px to 1399px */
@media (min-width: 960px) and (max-width: 1399px) {
  .pre-rex-container {
    margin-left: calc(2% + 10px) !important;
  }
  .not-eligible-prerex {
    margin-left: 5%;
  }
  .gift-icon-name {
    width:60%;
  }
  .offer-price-block {
    width:40%;
  }
}

/* Breakpoint for 1400px and above */
@media (min-width: 1400px) {
  .pre-rex-container {
    max-width: 1300px;
    margin-left: 4%;
  }
  .not-eligible-prerex {
    margin-left: 5%; 
  }
  .gift-icon-name {
    width:50%;
  }
  .offer-price-block {
    width:50%;
  }
}


.evi-prerex-list-container {
  margin-right: 10%;
}
.evi-prerex-list-combo {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin-bottom: 10px;
  padding: 10px;
}


.evi-prerex-list-item{
    border: 1px solid gray;
    margin-bottom: 10px;
    padding: 10px;
      &.rex-item-selected {
        border-color: $color-light-cyan;
      }
  &.reseller {
    overflow-wrap: break-word;
    background-color: #BBDAFF;
    ;
    label {
      display: flex;
      justify-content: space-between;
      
    }
    
    
  }
  
}



.evi-rex-item-icon {
  display: flex;
  padding-left: 20px;
  align-items: center;
  margin: 10px;
  img {flex-shrink: 0; margin-right: 10px}
  p {
    width: 100%;
    margin: 0 
  }
}

.evi-rex-summary {
  display: flex;
  max-width: 100%; /* Set a maximum width */
  padding: 20px;
  background-color: $color-secondary-blue;
  color: $color-dark-blue;
  box-sizing: border-box;
}

.rex-summary-gift {
  width: 70%;
  align-items: flex-start;
}

.rex-gift-details {
  text-align: right;
}

.horizontal-border {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 20px 0;
}

.rex-summary-gift p, .rex-summary-footer p {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.rex-summary-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pre-rex-action-button {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
}

// order confirmation
.horizontal-border-grey {
  width: 100%;
  height: 1px;
  background-color: $color-light-gray;
  margin: 10px 0;
}



.gift-item-descrption-font {
  color: $color-navy-blue;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px /* 150% */;
  letter-spacing: -0.25px;
}

.pre-rex-customer-info{
display: flex;
align-items: flex-start;
gap: 96px;
padding-top: 10px;
border-top: 1px solid $color-dark-smoke;
}
.pre-rex-customer-info p {
  margin: 0px;
}

.pre-rex-offer-price-info {
  display: flex;
  flex-direction: row;
  padding: 20px 0 10px 0;
  border-top: 1px solid $color-dark-smoke;


}

.gift-icon{
  margin: 0px 10px 0px 20px;
}
.gift-name {
  margin-right: 50px;
  padding: 0px 20px;
}

.gift-name p, .offer-price-block p {
  margin-block-start: 0em !important;
  margin-block-end: 0em;
}

.evi-download-app {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 20px;
  height: 24px;

  img {
    width: 24px;
    height: 24px;
  }
  span {
    position: absolute;
    margin: 0 0 -3px 65px;
  }
}


.pre-rex-summary-font-header {
  color: var(--Accent-Primary-Blue-800, #171C43);
  font-family: "Open Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px /* 147.368% */;
}

.pre-rex-summary-font-subheader {
  color: var(--Accent-Primary-Blue-800, #171C43);
  font-family: "Open Sans";
  font-size: 16px;
  font-style:normal;
  font-weight: 700;
  line-height: 24px /* 150% */;
  letter-spacing: -0.25px;
}

.pre-rex-summary-font-description {
  color: var(--Accent-Primary-Blue-800, #171C43);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px /* 150% */;
  letter-spacing: -0.25px;
}

.pre-rex-summary-ruutu {
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  max-width: 800px; 
  margin-bottom: 30px;
}
.top-zero-margin {
  margin-top: 0px;
}


// css for accordion and combo box
.prerex-accordion-label {
  display: flex;
  width: 100%;
  align-items: center;
}

.evi-prerex-campaign-header {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.prerex-campaign-radio {
  margin-right: 10px;
  flex-basis: 1%;
  text-align: center;
}

.prerex-campaign-name {
 width: 60%;
}

.prerex-campaign-price {
  flex-grow: 1;
  text-align: right;
  margin-right: 1.5%;
}

.prerex-bundle {
  display: flex;
  padding-left: 20px;
  margin: 10px 10px 20px 10px;
  img {flex-shrink: 0; width: 7%; margin-right: 5%}
  // span {margin-right: 10%; width: 50%}
}

.prerex-bundle-name {
  width: 30%;
}
.prerex-loading-small {
  width: 18px;
  height: 18px;
  border-width: 3px;
}

.evi-combine-summary {
  margin-bottom: 0px !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.padding-zero {
  padding: 0px !important;
}

.width-50 {
  width: 50% !important;
}