@use "sass:math";
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.evi-slider {
  position: relative;
  padding: 0 5%;

  &__slider {
    position: absolute;
    clear: both;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow: -moz-scrollbars-none;
    white-space: nowrap;
    top: 0;
    left: 5%;
    right: 5%;
    z-index: 2;

    @include accessibility-enabled {
      padding: 3px;
      margin: -3px;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
  }

  &--is-mobile &__slider {
    overflow-x: scroll;
  }

  &__slides {
    position: relative;
    margin: 0 math.div(-$slider-gutter-width, 2);
  }

  &--is-mobile &__slides {
    transform: none !important;
  }

  &--animate &__slides {
    pointer-events: none;
  }

  &:not(&--animate) &__slides {
    transition: none;
  }

  .evi-layout-wide &,
  .evi-trailer-stripe &,
  .evi-cast-stripe & {
    padding: 0;

    &__slider {
      left: 0;
      right: 0;
      padding: 0 5%;

      @include accessibility-enabled {
        padding: 3px 5%;
        margin: -3px 0;
      }
    }
  }
}
