@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

$breakpoint-phone-large-max-width: 639px;
$transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

.evi-modal {
  --neutral-color: #{$theme-modal-neutral-color};
  --neutral100-color: #{$theme-modal-neutral100-color};
  --neutral400-color: #{$theme-modal-neutral400-color};
  --link-color: #{$theme-modal-link-color};
  --button-color: #{$theme-modal-button-color};
  --neutral700-color: #{$theme-modal-neutral700-color};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: $zindex-overlay - 1; // This needs to be below the $zindex-overlay value so that the modal doesn't appear above viihde credentials user select modal.

  &__modal {
    position: fixed;
    top: 25px;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    max-height: 100vh;
    height: calc(100% - 25px);
    background: rgba(255, 255, 255, 0.95);
    max-width: 100%;
    width: 760px;
    transition: $transition;
    visibility: hidden;
    z-index: 10;
    border-radius: 10px 10px 0 0;
    transform: translateY(20%);
    display: flex;
    flex-direction: column;

    @include for-phone-large-down {
      background: #fff;
      width: 100%;
      padding: 0;

      > * {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: $transition;
    backdrop-filter: blur(7px);

    @include for-phone-large-down {
      display: none;
    }
  }

  &__header {
  }

  &__content {
    flex: 1;
    overflow-y: auto;
    padding: 0 30px;
    color: var(--neutral700-color);

    @include for-phone-large-down {
      padding: 0;
    }
  }

  &__logo {
    display: flex;
    align-self: center;
    user-select: none;
    width: auto;
    height: 55px;
    margin: 35px auto;
  }

  &__close {
    cursor: pointer;
    top: 38px;
    right: 25px;
    width: 45px;
    height: 45px;
    color: #010202;
    opacity: 0.5;
  }

  &--open {
    visibility: visible;

    & .evi-modal__modal,
    & .evi-modal__overlay {
      opacity: 1;
      visibility: visible;
    }

    & .evi-modal__modal {
      transform: translateY(0);
    }
  }
}

.evi-modal-bg-blur {
  filter: blur(5px);

  @include for-phone-large-down {
    filter: none;
  }
}

.evi-header--hidden {
  display: none;
  visibility: hidden;
}
