@import '../../../styles/variables.scss';

.evi-eventcard-indicator,
.evi-list-indicator {
  white-space: pre;
  color: #404041;
  text-transform: uppercase;
}
.evi-list-indicator {
  white-space: nowrap;
  margin-top: 0;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  line-height: 4rem;
  padding: 0;
  display: block;
}

.evi-eventcard-indicator {
  height: 30px;
  line-height: 32px;
  border-radius: 2px;
  margin-top: -3px;
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  width: 100%;
}
.evi-eventcard-indicator-starting,
.evi-list-indicator-starting {
  color: #fff !important;
  background: #00a950 !important;
}
.evi-eventcard-indicator-live,
.evi-list-indicator-live {
  color: #fff !important;
  background: #F66402 !important;
}
.evi-eventcard-indicator-archived,
.evi-list-indicator-archived {
  color: #FFF !important;
  background: #767677 !important;
}
.evi-eventcard-indicator-upComing,
.evi-list-indicator-upComing {
  background: #e0e0e1 !important;
}
.evi-eventcard-indicator-wide span {
  width: 75px!important;
}

.evi-list-indicator-wrapper {
  height: 100%;
  line-height: 78px;
  width: 80px;
  text-align: center;
  color: #404041;
  border-radius: $default-border-radius-left;
  overflow: hidden;
}
