@import '../../../styles/variables.scss';

.evi-purchase-wizard-offer-product-info {
  border-bottom: 1px solid $color-dark-smoke;

  &__name {
    flex: 1;
    padding-bottom: 11px;
  }

  &__header-row {
    display: flex;
    align-items: center;
  }

  &__cover {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px #E8E8EA;
    border-radius: 5px;
    overflow: hidden;

    > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  &__description {
    margin: 0;
  }
}

