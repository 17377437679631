@import '../../../styles/mixins.scss';

.evi-purchase-wizard-purchase-step {
  padding: 0 50px;

  @include for-phone-large-down {
    padding: 0 25px;
  }

  &__product-information {
    padding: 0 0 43px;
  }

  &__email-row {
    padding: 0 0 43px;
  }

  &__errors {
    padding: 0 0 30px;
  }

  &__show-base-product-button {
    margin-top: 30px;
  }
}
