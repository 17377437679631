@import '../../../styles/_variables';
.evi-program-list {
  &__wrapper {
    background: rgb(0, 71, 164);
    background: linear-gradient(0deg, rgba(16, 18, 33, 1) 0%, rgba(0, 71, 164, 1) 75%, rgba(16, 18, 33, 1) 100%);
    padding: 20px 0 30px 0;
    h3 {text-transform: capitalize;}
  }
  &__item {
    font-size: 1.3rem;
  }
  &__item:hover {
    cursor: crosshair;
    color: $color-light-cyan;
  }

  &__item-past {
    color: $theme-default-neutral500-color;
  }
  &__item-live {
    color: $color-orange;
  }
  &__item-live-indicator {
    font-weight: bold;
    &:before {
      content: ' \00BB '
    }
  }
}
