@import '../../../styles/mixins.scss';

.evi-free-payment-method {
  &__accordions {
    padding: 35px 0 0 0;
  }
  &__button {
    @include for-phone-large-down {
      min-width: 100%;
    }
  }
}
