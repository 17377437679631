@import '../../../styles/mixins.scss';

.evi-credit-card-method {
  &__checkbox {
    margin: 20px 0 20px;
  }

  &__saved-card {
    display: flex;
    align-items: center;
    margin: 20px 0 20px 0;

    &-icon {
      width: 26px;
      margin: 0 13px 0 0;
    }
  }

  &__toggle-payment-type-button {
    padding: 0;
    margin: 0 0 20px;
    color: var(--link-color);
    border: none;
    background-color: transparent;
  }

  &__existing-credit-card {
    padding: 0 0 35px 0;
  }

  &__new-credit-card {
    padding: 0 0 35px 0;
  }

  &__accordions {
    padding: 35px 0 0 0;
  }
}
