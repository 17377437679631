@import '../../../styles/mixins';

.evi-collapsible-text-block {
  color: var(--neutral700-color);

  &__header {
    margin: 0 5% 20px;
    text-align: center;
  }

  ul {
    list-style-type: disc;
    margin: 1em 0;
    padding-inline-start: 18px;
  }

  .evi-accordion-header {
    #viihdeonline-react-app-container & .evi-collapsible-text-block__accordion-header,
    #viihdeonline-react-modal-container & .evi-collapsible-text-block__accordion-header {
      font-size: 24px;
      font-weight: normal;
      padding: 0;
    }
  }

  .ea-accordion__element {
    padding-left: 5%;
    padding-right: 5%;
    border: none;
  }

  & + & .ea-accordion__element {
    border-top: 1px solid var(--neutral400-color);
  }

  .ea-accordion,
  .ea-accordion__element,
  .evi-accordion-header::after {
    color: inherit;
    text-align: center;
  }

  .ea-accordion__element--expanded>.ea-accordion__content {
    max-height: none;
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__content ul{
    padding: 0 42px 0 72px;
    text-align: left;
  }

  &--inside-modal {
    margin: 0 50px;
  }
}

.evi-product-details .evi-collapsible-text-block,
.evi-product-additional-info-wrapper .evi-collapsible-text-block,
.evi-collapsible-text-block--inside-modal {
  .evi-collapsible-text-block__header {
    text-align: left;
    margin: 0 0 16px;
  }

  .ea-accordion__element {
    padding-left: 0;
    padding-right: 0;
  }

  .evi-accordion-header {
    justify-content: left;
    padding-right: 40px;
    position: relative;

    &:after {
      position: absolute;
      top: 5px;
      right: 8px;
    }

    #viihdeonline-react-app-container & .evi-collapsible-text-block__accordion-header {
      font-size: 22px;
    }

    #viihdeonline-react-modal-container & .evi-collapsible-text-block__accordion-header {
      font-size: 20px;
    }
  }

  .evi-accordion-content {
    padding-bottom: 0;

    .ea-fgrid {
      margin: 0;
      padding: 0;

      .evi-collapsible-text-block__content {
        margin: 10px 0 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 12px;
      }
    }
  }
}
