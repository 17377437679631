.evi-registration-consents {
  &__spinner {
    display: flex;
    height: 300px;
    align-items: center;
  }

  .ea-disclaimertext {
    font-size: 13px;
  }

  .ea-checkbox {
    display: inline-block;
    margin-right: 20px;
  }

  &__consent {
    margin-top: 12px;
  }
}

.evi-consent-bundle {
  p {
    margin: 0;
  }

  .evi-consent-permission-heading {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 5px;
  }
}
