@import '../../../styles/variables';

.evi-recording-versions-block {

  .evi-watchable-container {

    button {
      color: var(--accent800-color);
      border-color: var(--accent800-color);
      opacity: 0.85;

      &:hover {
        opacity: 1;
      }
    }

    .evi-recording-versions-button-group {
      margin-top: 55px;

      button:not(:last-child) {
        margin-right: 24px;
      }

      button.disabled {
        pointer-events: none;
        color: var(--neutral500-color);
        border-color: var(--neutral500-color);
        opacity: 1;
      }

      @media screen and (max-width: $breakpoint-phone-max-width) {
        margin-top: 40px;

        button:last-child {
          margin-top: 15px;
        }
      }
    }

    .evi-version-info-row {
      flex-wrap: nowrap;

      &:not(:last-child) {
        margin-bottom: 32px;
      }


      .evi-play-icon--container {
        align-self: center;

        svg.evi-play-icon {
          margin-right: 30px;
    
          ellipse {
            transition: fill 0.3s ease-out;
            transition: opacity 0.3s ease-out;
          }
    
          &:hover {
            ellipse {
              fill: var(--accent400-color);
              opacity: 1;
            }
            circle {
              fill: var(--accent400-color);
              opacity: 1;
            }
          }
        }
      }

      .evi-delete-recording-button--container {
        align-self: center;

        .evi-delete-recording-button {
          margin-left: 70px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .evi-delete-recording-button:hover {
          background-color: unset;
        }
        
        @media screen and (max-width: $breakpoint-phone-max-width) {
          flex-grow: 1;

            .evi-delete-recording-button {
              margin-left: 10px;
              margin-right: 0px;
              float: right;
            }
        }
      }
    }

    .evi-version-info {
      width: 100%;
      max-width: 270px;

      &__primary {
        font-size: 19px;
        color: var(--accent800-color);
      }

      &__secondary {
        font-size: 14px;
        color: var(--neutral600-color);
      }
    }
  }

}
