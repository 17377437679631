@import '../styles/variables';
@import '../styles/mixins';

.evi-purchase-form {
  position: relative;

  /* override theme color */
  color: #404041;

  &.evi-purchase-form--side {
    padding: 20px;
    background: #f3f3f3;
  }

  .ea-accordion__element {
    padding: 0
  }

  .ea-accordion__header {
    .ea-bodytext {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
  .ea-radio label {
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 0;
  }

  .ea-tooltip {
    position: relative;
    top: 4px;

    @media only screen and (max-width: $breakpoint-phone-max-width) {
      .ea-tooltip__text {
        width: auto;
      }
    }
  }

  .ea-accordion__content {
    padding-right: 0;
  }

  .ea-input__wrapper {
    flex-shrink: 2;
    flex-grow: 2;
    flex-basis: 120px;
    margin-right: 12px;
  }

  .ea-input--button {
    padding-bottom: 0;
    .ea-input__wrapper, .ea-button {
      margin-bottom: 12px;
    }
  }

  .evi-purchase-coupons {
    border-top: 1px solid #d0d0d1;
    border-bottom: 1px solid #d0d0d1;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ea-input--half {
    max-width: 50%;
  }
}

.evi-quality-selector-text {
  white-space: nowrap;
}
.evi-title--coverImage {
  width: 100%;
  max-width: 100%;
  border-radius: $default-border-radius;
  background-size: cover;
  background-position: center;
}

.ea-accordion__element {
  border-bottom: none;
}

.evi-purchase-form .ea-price-text {
  text-align: left;
  .ea-price-text__price {
    font-size: 30px;
    font-weight: 400;
  }
  .ea-price-text__unit {
    font-weight: 300;
  }
}

.evi-cancel-recording-button>.ds-button__content {
  flex-direction: column;
}

.evi-cancel-recording-button:hover {
  background-color: unset;
}

.evi-offer-quality-selector,
.evi-favorite-link,
.evi-trailer-link,
.evi-share-button,
.evi-delete-recording-button,
.evi-cancel-recording-button,
.evi-calendar-button,
.evi-recording-versions-button {
  cursor: pointer;
  text-transform: uppercase;
  color: inherit;
}

.evi-favorite-link,
.evi-share-button,
.evi-delete-recording-button,
.evi-cancel-recording-button,
.evi-calendar-button,
.evi-recording-versions-button {
  background: none;

  @include accessibility-not-enabled {
    border: none;
    outline: none;
  }
}

.evi-offer-quality-selector,
.evi-favorite-link,
.evi-trailer-link,
.evi-share-button,
.evi-delete-recording-button,
.evi-cancel-recording-button,
.evi-calendar-button,
.evi-recording-versions-button {
  .ea-icon {
    font-size: 23px;
    line-height: 1;
    margin: 0;
  }

  .evi-icon-quality {
    margin: 0;

    svg {
      max-height: 23px;
    }
  }
  
  span {
    margin-top: 7px;
    font-size: 16px;
    line-height: 1.2;
  }
  
}

.favorite-icon,
.trailer-icon {
  width: 100%;
  max-width: 25px;
  fill: var(--neutral700-color);

  * {
    stroke: var(--neutral700-color) !important;
  }
}

.evi-give-creditcard {
  &, :first-child {
    margin-top: 0;
  }
}

.evi-test-movie {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 15px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  padding: 40px 20px;
  color: #808081;
  border: 4px solid #808081;
  background: #000;

  .ea-icon {
    display: inline-block;
    font-size: 100px;
    color: white;
  }
}

.evi-imdb-rating {
  margin-right: 10px;
}

.evi-icon-imdb {
  margin: 0 10px -7px 0;
  width: 55px;
  height: 27px;
}

.evi-icon-51 {
  width: 55px;
  height: 27px;
  margin: 0 10px -7px 0;
}

.evi-theme-logo {
  margin: 6px 0;
  max-height: 32px;
}

.ea-ratings {
  width: 28px;
  height: 28px;
  margin: 0 0 -8px 4px;
  display: inline-block;
  color: var(--neutral600-color);
}


.evi-series-container,
.evi-event-container {
  .evi-offer-quality-selector,
  .evi-favorite-link,
  .evi-trailer-link,
  .evi-share-button,
  .evi-delete-recording-button,
  .evi-cancel-recording-button,
  .evi-calendar-button,
  .evi-recording-versions-button {
    padding: 0;
    margin: 10px 0 0 10px;

    >.ea-icon {
      padding-right: 20px;
    }

    svg {
      margin: 0 10px 0 0;
    }

    span {
      margin-top: 2px;
    }
  }
}

.evi-watchable-container {
  padding: 4% 5% 8%;

  .evi-event-content {
    overflow: visible;
  }

  .evi-title,
  .evi-event {
    font-size: 36px;
    &-genres,
    &-countdown {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 8px;
    }
    &-metadata {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 600;
    }
    &-header {
      padding-bottom: 8px;
    }
  }

  .evi-title {
    &-deeplink-info {
      font-style: italic;
      margin-top: 16px;
    }
  }

  .evi-title-actions {
    .evi-delete-recording-button {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .evi-offer-quality-selector,
  .evi-favorite-link,
  .evi-trailer-link,
  .evi-share-button,
  .evi-delete-recording-button,
  .evi-cancel-recording-button,
  .evi-calendar-button,
  .evi-recording-versions-button {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin-right: 25px;
  }

  .evi-title-purchase-cover-wrapper {
    display: flex;
    margin: 32px 0 10px 0;

   .evi-purchase-buttons {
     align-items: center;
     display: flex;
     margin-right: 25px;

     .evi-purchase-button-group {
       display: flex;
       >div {
         padding-right: 12px;
       }
       .ea-button {
         white-space: nowrap
       }
     }
     .evi-action-button-group {
       >div {
         .ea-pad {
           padding: 0;
         }
       }
     }
    }

    .evi-title-play-link-no-cover {
      color: $color-white-smoke;
      line-height: 28px;
      display: flex;
      justify-content: center;
      text-shadow: none;
      white-space: nowrap;
      padding-top: 10px;

      .evi-title-play-icon {
        font-size: 28px;
        padding-right: 8px;
        filter: none;
      }
    }

    .evi-purchase-action-links {
      display: flex;
      white-space: nowrap;
    }

    .evi-purchase-video-subtitle {
      position: absolute;
      top: 68px;
    }
  }
}

.evi-offer-release-date,
.evi-video-sub-title {
  font-size: 13px;
  line-height: 1.2;
  margin: 10px 0;
}

.evi-title--mobile-cover-image {
  display: none;
}

.evi-event-information-block-background {
  background-repeat: no-repeat;
  background-position: right -5%;
  background-size: unset;
  &--disabled {
    background: none !important;
  }
}

.evi-event-purchase {
  display: flex;
  margin-top: 32px;

  button:first-child:not(.evi-favorite-link) {
    margin-left: 0;
  }

  &-button {
    margin-right: 12px;
  }

  .evi-event-user-actions {
    display: flex;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: $breakpoint-phone-large-max-width) {
    .evi-event-user-actions {
      align-self: center;

      .evi-offer-quality-selector,
      .evi-favorite-link,
      .evi-trailer-link,
      .evi-share-button,
      .evi-delete-recording-button,
      .evi-cancel-recording-button,
      .evi-calendar-button,
      .evi-recording-versions-button {
        margin: 0 15px;
      }
    }
  }
}

.social-media-share-overlay {
  backdrop-filter: blur(7px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }

  &--content {
    color: $color-dark-grey;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
    background: rgba(255,255,255,0.95);
    padding: 90px;
    border-radius: 10px;
    transition: all 0.3s ease;
    white-space: nowrap;
  }

  &--close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 45px;
    height: 35px;
    line-height: 45px;
    text-align: center;
    color: #b0b0b1;
    transition: color 0.5s ease;

    @include accessibility-not-enabled {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      color: $color-dark-grey;
    }

    &:before {
      display: block;
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet-min-width) {
  .evi-event-purchase {
    flex-direction: column;
    .evi-event-purchase-button {
      margin: 0;
      > button {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  .evi-offer-release-date,
  .evi-video-sub-title {
    text-align: center;
    margin-top: 0;
  }

  .evi-title-play-link-no-cover {
    margin-bottom: 12px;
  }

  .evi-purchase-action-links {
    margin-top: 5px !important;
  }
}

@media only screen and (max-width: $breakpoint-laptop-small-min-width) {
  .evi-watchable-information-block--cover-background {
    position: relative;

    .evi-watchable-container {
      margin-top: 260px;
    }

    .evi-title,
    .evi-event {
      &--mobile-cover-image {
        width: 165px !important;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        background-size: auto 100%;
        background-repeat: no-repeat;
        margin: 20px 0 0;
        border-radius: 3px;
        -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.75);
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.75);
      }
    }
  }

  .evi-title,
  .evi-event {
    &-header {
      font-size: 30px;
      padding-bottom: 6px;
      padding-top: 2px;
    }
    &-metadata {
      font-size: 13px !important;
      margin-bottom: 8px !important;
    }
    &-genres,
    &-countdown {
      font-size: 13px !important;
      padding-bottom: 0 !important;
    }
    &-countdown {
      margin-bottom: 6px;
    }
    &--mobile-cover-image {
      display: block;
      width: 100% !important;
      max-height: 50vh;
    }
    &-details-actors-all {
      max-height: 300px;
    }
  }
  .evi-watchable-container {
    padding: 4% 5%;
  }
  .evi-title-purchase-cover-wrapper {
    margin: 12px 0 10px 0 !important;
    flex-direction: column;
    >.evi-purchase-buttons {
      flex-direction: column;
      .evi-purchase-button-group {
        flex-direction: column;
        width: 100%;
        >div {
          padding-right: 0 !important;
          margin-bottom: 12px;
        }
      }
    }
    .evi-offer-quality-selector,
    .evi-favorite-link,
    .evi-trailer-link,
    .evi-share-button,
    .evi-delete-recording-button,
    .evi-cancel-recording-button,
    .evi-calendar-button,
    .evi-recording-versions-button {
      margin-left: 0;
    }
  }

  .evi-purchase-action-links {
    margin-top: 12px;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .evi-icon-imdb {
    width: 35px;
    margin: 0 8px -9px 0;
  }
  .evi-icon-51 {
    margin: 0 4px -9px 0;
    width: 35px;
  }

  .ea-ratings {
    width: 20px;
    height: 20px;
    margin: 0 0 -5px 4px;
  }
}

@media only screen and (max-width: $breakpoint-tablet-max-width) {
  .evi-title-metadata {
    line-height: 2em;
  }
}

@media only screen and (max-width: $breakpoint-phone-large-max-width) {
  .evi-watchable-container, .ea-episode {
    justify-content: center;
  }
  .evi-watchable-details-container {
    padding-left: 5%;
  }
}

@media only screen and (min-width: $breakpoint-laptop-small-min-width) {
  .evi-title-purchase {
    padding-right: 20px;
    .evi-purchase>.ea-notification--error {
      margin: 24px 0 -12px 0;
    }
  }

  .evi-watchable-container {
    max-width: 60%;
  }

  .evi-watchable-information-block {
    .evi-background:before {
      content: '';
      padding-bottom: 56.25%;
      float: left;
    }
  }
}
@media only screen and (min-width: $breakpoint-laptop-small-min-width) and (max-width: $breakpoint-laptop-large-min-width) {
  .evi-watchable-information-block {
    .evi-background:before {
      padding-bottom: 82%;
    }
  }
  .evi-event-information-block-background {
    background-position: right top;
  }
}

@media only screen and (min-width: $breakpoint-laptop-min-width) {
  .evi-title-play-link-no-cover {
    margin-right: 25px;
  }
  .evi-watchable-container {
    max-width: 65%;
  }
}

@media only screen and (min-width: $breakpoint-desktop-min-width) {
  .evi-watchable-container {
    max-width: 60%;

    .evi-event-information-block &:before {
      content: '';
      padding-bottom: 42%;
      float: left;
    }
  }
  .evi-event-information-block-background {
    background-position: right -10%;
  }
}

.evi-purchase-cover-wrapper {
  max-width: 320px;
  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    margin-left: auto;
    margin-right: auto;
  }
}

.evi-watchable-details-container {
  padding-left: 5%;
  margin-bottom: 20px;
}

.evi-recording-button {
  white-space: nowrap;
}
