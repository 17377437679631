@import '../../../../styles/variables.scss';

.evi-offer-price-info {
  margin: 35px 0 0 0;
  padding: 35px 0 16px 0;
  border-top: 1px solid $color-dark-smoke;

  &__price {
    display: flex;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $color-dark-grey;
    margin: 0 0 3px 0;

    &-label {
      flex: 1;
    }
  }

  &__resolution {
    text-align: right;
    font-size: 13px;
    line-height: 18px;
    color: #70707A;
  }
}
