@import '../../../styles/variables.scss';

$transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

.evi-toast-notification {
  position: relative;
  transition: $transition;
  transform: translateY(-100%);
  opacity: 0;
  z-index: 100;

  &--open {
    transform: translateY(0);
    opacity: 1;
  }

  .ea-notification {
    border-radius: 0;
  }

  .evi-toast-button {
    color: #fff;
    border-color: #fff;
    margin-left: 32px;
  }
}
