@import '../../styles/variables.scss';

.evi-title-actions {
  padding: 32px 0 10px 0;

  &__actions {
    margin: 0 -4px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    > * {
      margin: 0 4px;
    }
  }

  &__primary-action {
    text-align: center;
    margin: 0 25px 0 0;

    &:empty {
      display: none;
    }

    & > button {
      margin: 0 4px 8px 0;
    }
  }

  &__secondary-action {
    display: flex;
  }
}

@media only screen and (max-width: $breakpoint-laptop-small-max-width) {
  .evi-title-actions__actions {
    flex-direction: row;
    gap: 15px;

    .evi-title-actions__primary-action {
      flex-wrap: wrap;
      flex-grow: 0;
      margin: 0 4px 0 4px;
  
      .evi-watchable-purchase-button {
        button {
          width: 100%;
        }
      }

      & > button {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  
    .evi-title-actions__secondary-action {
      padding: 0;
      margin-bottom: 15px;
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-around;

      .evi-favorite-link,.evi-share-button{
        margin-right: 0px;
      }
  
      button:first-child {
        margin-left: 0px !important;
      }
    }
  }

@media only screen and (max-width: $breakpoint-phone-large-max-width) {
  .evi-title-actions__actions {
    flex-direction: column;
    gap: 0px;

    .evi-title-actions__primary-action {
      flex-direction: column;
    }

    .evi-title-actions__secondary-action {
      margin-top: 15px;
    }
  }
}

  .evi-purchase-additional-info {
    .evi-video-sub-title {
      text-align: left;

    }
  }
}
