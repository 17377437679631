@import '../../../styles/_mixins.scss';

.evi-payment-card-management {
    .ea-notification {
        margin: 1em 0;
    }

    .ea-button {
        position: relative;
        top: -2px;
        margin-left: 2em;

        @include for-phone-large-down {
            margin: 1em 0 0;
        }
    }

    .ea-button--icon {
        padding-left: 50px;
    }

    &--list {
        margin: 1em 0;

        &-item {
            margin-top: 1em;

            @include for-phone-large-down {
                & > span {
                    display: block;
                }
            }
        }

        .payment-card-icon {
            position: relative;
            top: -2px;
            max-width: 30px;
            vertical-align: middle;
            margin: 0 4px 0 -2px;
        }
    }
}