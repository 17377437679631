@import '../../styles/mixins';
@import '../../styles/variables';

.evi-event-card-skeleton {
  display: flex;
  flex-direction: column;

  &__cover {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    background-color: var(--neutral100-color);
    border-radius: $default-border-radius $default-border-radius 0 0;
    padding: $watchable-card-height-event 0 0 0;
  }

  &__status {
    height: 30px;
    border-radius: 0 0 $default-border-radius $default-border-radius;
    background-color: var(--neutral100-color);
  }

  &__title {
    height: 16px;
    margin: 9px 10px 4px;
    background-color: var(--neutral100-color);
  }

  &__secondary-title {
    height: 14px;
    margin: 5px 10px;
    background-color: var(--neutral100-color);
    width: 50%;
  }
}
