@import '../../../styles/mixins.scss';

.evi-purchase-wizard-coupons {
  padding: 0 0 35px;

  &__price-after-discount {
    padding: 31px 0 0;
  }
}

.evi-purchase-wizard-coupons:empty {
  padding: 0;
}
