@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';

.evi-purchase-wizard-device-selection {
  @include for-desktop-up {
    .evi-combobox .ea-input {
      width: 100%;
    }
  }

  &__errors {
    padding: 0 0 30px;
  }

  &__elisa-store-button {
    margin-top: 30px;
  }
}
