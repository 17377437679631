@import '../../../styles/mixins';

.evi-text-block {
  &:not(&--inside-modal) {
    @include useTheme();
  }

  img {
    max-width: 100%;
  }

  &__action-buttons {
    display: flex;
    justify-content: center;
    padding: 13px 0 0;
  }

  &--inside-modal {
    margin: 0 50px 24px;

    .ea-snapper-fluid-container .ea-fgrid--gutter {
      margin: 0;

      .ea-fgrid__item {
        margin: 0;
        padding: 0;
      }
    }

    hr {
      border: none;
      border-bottom: 1px solid var(--neutral400-color);
      margin: 16px 0;
    }
  }
}
