.evi-terms-of-service {
  &__description {
    margin-top: 0;
  }

  &__header {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  li {
    margin-bottom: 5px;
  }
}
