@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.evi-sign-in {
  &__header,
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-phone-large-down {
      margin: 0 5%;
    }
  }

  &__heading {
    padding: 24px 0;
  }

  &__tabs {
    display: flex;
    max-width: 400px;
    width: 100%;
    padding-bottom: 8px;
  }

  &__tab {
    background-color: $color-white-smoke;
    border-color: $color-dark-grey;
    border-style: solid;
    border-width: 2px 1px 2px 1px;
    text-transform: uppercase;
    padding: 0 15px;
    line-height: 36px;
    font-size: 13px;
    font-weight: 600;
    width: 50%;

    &:focus {
      outline: none;
      box-shadow: 0 0 4px 1px var(--button-color);
    }

    &:first-child {
      border-radius: 2px 0 0 2px;
      border-left-width: 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
      border-right-width: 2px;
    }

    &--active {
      background-color: $color-dark-grey;
      color: $color-dark-grey;
      position: relative;

      & > span {
        color: $color-white-smoke;
      }

      &:after {
        outline: none;
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin: 2px 0 0 -8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid;
      }
    }

    &-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .evi-login {
    width: 100%;
    max-width: 400px;
    padding: 20px 0;

    &__heading {
      display: none;
    }
  }

  &--show-tabs {
    .evi-registration-form__heading {
      display: none;
    }
  }

  .evi-sign-in__tab {
    border-color: var(--neutral700-color);
    background-color: var(--neutral100-color);

    &--active {
      border-color: var(--neutral700-color);
      background-color: var(--neutral700-color);
      color: var(--neutral700-color);

      .evi-sign-in__tab-text {
        color: var(--neutral400-color);
      }
    }
  }

  &__help {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    & > div, & > h3 {
      width: 100%;
      max-width: 400px;
    }
  }
}
