@import '../../../../styles/variables';

.evi-card-item {
  display: flex;

  &__icon {
    padding: 0 16px 0 0;

    img {
      color: $color-linkblue;
      width: 32px;
      font-size: 24px;
    }
  }

  &__content {
    flex: 1;
  }

  &__model {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &__card-number {
    font-size: 13px;
    line-height: 19px;
  }
}
