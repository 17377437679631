@import '../../../styles/variables';

.evi-tab-bar {
  &-tabs {
    display: inline-block;
    border-bottom: 1px solid var(--neutral400-color);
  }

  &-button {
    cursor: pointer;
    line-height: 20px;
    padding: 14px 0px;
    background: none;
    border: none;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &--active {
      border-bottom: 2px solid $color-white;
      font-weight: 700;
    }
  }

  &--seasons {
    .content {
      margin-top: 1.5em;
    }

    .evi-tab-bar-tabs {
      max-width: calc(320px + 24px + 50%);
    }
  }
}
