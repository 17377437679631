.evi-countdown--blocks {
  overflow: hidden;padding-bottom: 20px;
}
.evi-countdown--header {
  font-size: 20px;
}
.evi-countdown--block {
  float: left;
  width: 90px;
  background: #eee;
  margin: 0 2px;
  -webkit-box-shadow: 4px 4px 4px 1px #999;
  box-shadow: 4px 4px 4px 1px #999;

  span {
    display: block;
    text-align: center;
  }
  .evi-countdown--block--header {
    font-weight: bold;
    background: #006dfc;
    color: #FFF;
    padding: 5px;
  }
  .evi-countdown--block--value {
    font-size: 30px;
    padding: 20px;
    min-height: 64px;
    display: block;
  }
  &.evi-countdown--block-seconds {
    .evi-countdown--block--value {
      background: #ccc;
    }
  }
}


.dark {
  .evi-countdown--block--header {
    background: #333;
  }
  .evi-countdown--block--value {
    background: #666;
    color: #FFF;
  }
  .evi-countdown--block-seconds {
    .evi-countdown--block--value {
      background: #999;
    }
  }
}
