.evi-svod-purchase-option {
  &__header {
    display: flex;
  }

  &__header-column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__name {
    padding: 16px 0;
  }

  &__description {
    margin: 0 0 24px;
  }

  &__icon {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px #E8E8EA;
    border-radius: 5px;
    overflow: hidden;

    > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }
}
