// Standard breakpoints in Elisa Styleguide
$breakpoint-phone-max-width: 479px;
$breakpoint-phone-large-min-width: 480px;
$breakpoint-phone-large-max-width: 639px;
$breakpoint-tablet-min-width: 640px;
$breakpoint-tablet-max-width: 959px;
$breakpoint-laptop-min-width: 960px;
$breakpoint-laptop-max-width: 1399px;
$breakpoint-desktop-min-width: 1400px;

// Custom breakpoints in Viihde
$breakpoint-laptop-small-min-width: 960px;
$breakpoint-laptop-small-max-width: 1199px;
$breakpoint-laptop-large-min-width: 1200px;
$breakpoint-laptop-large-max-width: 1399px;
$breakpoint-desktop-max-width: 1919px;
$breakpoint-desktop-large-min-width: 1920px;

$header-height: 61px;
$header-height-large: 67px;

$color-white: #ffffff;
$color-smoke: #e0e0e1;
$color-light-smoke: #fafafa;
$color-dark-grey: #404041;
$color-linkblue: #006dfc;
$color-secondary-blue: #DEEDFF;
$color-light-cyan: #aae0fa;
$color-dark-blue: #002447;
$color-dark-smoke: #d0d0d1;
$color-white-smoke: #f5f5f5;
$color-grey: #767677;
$color-green: #00a950;
$color-error: #ee4444;
$color-orange: #f66000;
$color-skeleton-loader: $color-white-smoke;
$color-favorite: #fcaf17;
$color-hover: #272C54;
$color-light-gray: #d2d2d6;
$color-navy-blue: #171C43;

$color-notification-default: $color-grey;
$color-notification-success: #008253;
$color-notification-error: #E0022A;
$color-notification-warning: #fcaf17;
$color-notification-info: $color-linkblue;

// Theming
// Notice! DO NOT use these variables in you styles. Use the corresponding CSS variables.
$theme-default-neutral-color: #101221;
$theme-default-highlight-color: #F2F5FB;
$theme-default-neutral100-color: #17182C;
$theme-default-neutral200-color: #1B1D37;
$theme-default-neutral300-color: #262D43;
$theme-default-neutral400-color: #384056;
$theme-default-neutral500-color: #7B859E;
$theme-default-neutral600-color: #C9D3E5;
$theme-default-neutral700-color: #F2F5FB;
$theme-default-neutral800-color: #F9FAFC;
$theme-default-accent400-color: #006AED;
$theme-default-accent600-color: #96C5FF;
$theme-default-accent800-color: #DEEDFF;
$theme-default-button-color: #006DFC;
$theme-default-link-color: #0090f1;
$theme-default-shadow-color: #010103;

$theme-default-status600-color: #E8163C;

$theme-modal-neutral-color: $color-white;
$theme-modal-neutral100-color: $color-white-smoke;
$theme-modal-neutral400-color: $color-dark-smoke;
$theme-modal-link-color: #0068f0;
$theme-modal-button-color: #0068F0;
$theme-modal-neutral700-color: $color-dark-grey;

$elisaicon-search: "\EA21";
$elisaicon-cross: "\EA52";
$elisaicon-arrow-down: "\EA5A";
$elisaicon-checkmark: "\2713";

$fullscreen-top-offset: 0;

$global-font-icon: 'Elisaicons-7.30.0';
$global-font-icon-custom: 'Elisaicons-custom';

$global-border-thick: 2px;
$global-baseline: 12px;
$global-column-cap: 20px;

$zindex-dropdown-normal: 10;
$zindex-dropdown-fullscreen: 10001;
$zindex-icons: 10;
$zindex-overlay: 80;

$eventcard-height-desktop: 180px;
$eventcard-width-desktop: 320px;

$eventcard-height-phone: 80px;
$eventcard-width-phone: 150px;

$eventcard-height-tablet: 120px;
$eventcard-width-tablet: 215px;

$default-border-radius: 2px;
$default-border-radius-left: 2px 0 0 2px;

$player-opacity-transition: opacity 0.3s ease;
$player-visibility-transition: visibility 0.3s ease;
$player-controls-margin-desktop: 1%;
$player-controls-margin-mobile: 2%;

$slider-gutter-width: 8px;
$slider-transition: transform 0.65s ease;

$watchable-card-height-default: 150%;
$watchable-card-height-landscape: 56.25%;
$watchable-card-height-event: 56.25%;
$watchable-card-height-giant: 150%;
$watchable-card-height-cast: 140%;

$event-card-status-color-starting: #ffffff;
$event-card-status-color-live: #ffffff;
$event-card-status-color-archived: #ffffff;
$event-card-status-color-up-coming: $color-dark-grey;

$event-card-status-background-starting: $color-green;
$event-card-status-background-live: #F66402;
$event-card-status-background-archived: #767677;
$event-card-status-background-up-coming: #e0e0e1;
