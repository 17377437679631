.evi-trailer-stripe {
  .ea-snapper-fluid-container {
    max-width: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px;
    padding: 12px 5% 0;
    position: relative;
  }

  &__title {
    margin: 0;
    padding: 0;
  }

  &:not(&--has-intersected) &__header &__title,
  &--is-loading &__header &__title {
    background: var(--neutral100-color);
    color: transparent !important;
  }

  &:not(&--has-intersected) &__header a,
  &--is-loading &__header a {
    display: none;
  }
}
