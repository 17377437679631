@import '../../styles/variables.scss';

.evi-eventcard-list-element {
  width: 100%;
  height: 80px;
  background: var(--neutral100-color);
  display: flex;
  margin: 0 0 30px 0;
  border-radius: $default-border-radius;
  box-shadow: 1px 2px 20px #080809;
  transition: opacity 3s ease;

  .ea-event-thumbnail__description-text,
  .evi-list-indicator-wrapper,
  &__cover-image img {
    transition: opacity 0.3s ease;
  }

    a {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .ea-event-thumbnail__description-text {
    margin: 0 1% 0 1%;
    color: #404041;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    color: var(--neutral700-color);

    > h3 {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > p {
      margin: -5px 0 0 0;
    }
  }
  .evi-icon-quality {
    zoom: 1.5;
    margin-left: 5px;
    margin-top: -20px;
    color: #404041;
  }

  &__cover-image {
    height: 96px;
    position: relative;
    overflow: hidden;
    border-radius: $default-border-radius $default-border-radius 0 0;
    z-index: 1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__progress {
    position: absolute;
    height: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(235, 235, 235, 0.4);
    z-index: 2;

    // Fix for iOS rendering issue with -webkit-overflow-scrolling: touch;.
    transform: translate3d(0, 0, 0);

    &-bar {
      display: block;
      background: $color-linkblue;
      height: 100%;
      border-bottom-left-radius: 2px;
    }
  }
  &--is-loading {
    .ea-event-thumbnail__description-text,
    .evi-list-indicator-wrapper,
    img  {
      opacity: 0;
    }
  }

  &--is-loaded {
    .ea-event-thumbnail__description-text,
    .evi-list-indicator-wrapper,
    img  {
      opacity: 1;
    }
  }
}
.evi-eventcard-list-element:last-child {
  margin: 0;
}

.evi-eventcard-list-element-error {
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  border-radius: $default-border-radius;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    margin-right: 10px;
  }
}

.evi-eventcard-list-icon {
  cursor: pointer;
  height: 100%;
  width: 78px;
  text-align: center;
  span {
    line-height: 78px;
    color: var(--neutral700-color);
  }
}
.evi-event-vertical-list {
  .evi-infinite-list {
    margin-right: 0;
  }
  .evi-eventcard-wrapper {
    position: relative;
    min-width: 160px;
    height: 90px;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.15));
    border-radius: $default-border-radius;
  }
  .evi-eventcard-indicator-date {
    background: #d8d8d8;
  }
  .ea-accordion {
    margin: 0 5%;
  }
  .ea-accordion__header {
    padding: 0;
    text-transform: capitalize;
  }
  .ea-accordion__header:before {
    line-height: 60px;
  }
  .ea-accordion__content {
    padding-right: 0;
  }
  .ea-accordion__element {
    padding: 0;
  }
  .ea-accordion__element--expanded > .ea-accordion__content {
    overflow: visible;
  }
  .ea-accordion__element:last-child {
    padding-bottom: 20px;
  }
  .ea-icon.ea-icon--selected {
    color: #0019af;
  }

  @media only screen and (max-width: $breakpoint-phone-large-max-width) {
    .evi-eventcard-list-icon {
      display: none;
    }
    .evi-eventcard-list-element {
      height: 50px;
      margin-bottom: 17px;
      .evi-icon-quality {
        zoom: 0.8 !important;
        margin-top: -20px !important;
      }

      &__cover-image {
        height: 55px;
      }
    }
    .evi-eventcard-list-element-error {
      height: 50px;
      margin-bottom: 17px;
      span {
        font-size: 10px;
        line-height: 10px;
      }
      .ea-button {
        zoom: 0.5;
      }
    }

    .evi-list-indicator-wrapper {
      width: 40px;
      min-width: 40px;
      span {
        line-height: 25px;
      }
    }
    .evi-list-indicator {
      font-size: 7px;
    }
    .evi-eventcard-wrapper {
      height: 55px;
      min-width: 85px;
    }
    .ea-event-thumbnail__description-text {
      margin: 0 2% 0 2%;
      max-width: 54%;
      > p {
        font-size: 1.05rem;
      }
      > h3 {
        font-size: 1.5rem;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    .evi-eventcard {
      padding: 10px 5px;
    }
    .evi-eventcard-list-element-error {
      padding: 0 10px;
    }
  }
  @media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
    .evi-eventcard-list-element,
    .evi-eventcard-list-element-error {
      height: 64px;
      margin-bottom: 24px;
      .evi-icon-quality {
        zoom: 1.5 !important;
        right: 180px !important;
      }
      &__cover-image {
        height: 79px;
      }
    }
    .evi-eventcard-list-icon {
      width: 65px;
      span {
        line-height: 6.2rem;
      }
    }
    .evi-list-indicator-wrapper {
      width: 65px;
      min-width: 65px;
      span {
        line-height: 3.1rem;
      }
    }
    .evi-list-indicator {
      font-size: 1.1rem;
      line-height: 6.2rem;
    }
    .evi-eventcard-wrapper {
      min-width: 130px;
      height: 74px;
    }
    .evi-eventcard--image {
      height: 74px !important;
    }
    .ea-event-thumbnail__description-text {
      max-width: 300px;
    }
  }
}
