.evi-app-buttons {
  max-width: 310px;

  &__buttons {
    display: flex;
    flex-flow: wrap;
    margin: 0 -12px;
  }

  &__button {
    width: 50%;
    max-width: 100%;
    padding: 0 12px 8px;

    a {
      display: block;
    }

    img {
      display: block;
    }
  }
}
