@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-hero {
  @include useTheme();

  .evi-theme-logo {
    width: 8vw;
    height: auto;
    position: absolute;
    right: 2.6vw;
    top: 2vw;
    z-index: 2;
  }

  .ea-fluid-container__background-image {
    &:after {
      content: none;
    }

    margin: 0;

    img {
      max-width: 100%;
    }
  }

  .evi-hero-background__gradient {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .ea-fluid-container__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .evi-hero-logo-image {
      max-width: 320px;
      max-height: 48px;
      margin-bottom: 12px;
    }

    .evi-hero-title,
    .evi-hero-subtitle,
    .ea-bodytext {
      padding-bottom: 12px;
    }

    .evi-hero-description {
      padding-bottom: 16px;

      h1 {
        padding-bottom: 16px;
      }

      p {
        padding-bottom: 12px;
        margin-block-end: 0px;
      }

      :last-child {
        padding-bottom: 0;
      }
    }

    .evi-hero-buttons {
      margin-top: 12px;

      & .ea-loading {
        display: inline-block;
      }
    }

    .evi-hero-subtitle,
    .ea-bodytext {
      font-size: 18px;
      line-height: 28px;
    }

    .evi-hero-title {
      word-break: normal !important;
      font-weight: 500 !important;
    }

    .evi-hero-title,
    .evi-hero-subtitle,
    .ea-bodytext,
    .evi-hero-description {

      // 640 - 959
      @media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
        & {
          max-width: 45vw;
        }
      }

      // 960 - 1199
      @media only screen and (min-width: $breakpoint-laptop-small-min-width) and (max-width: $breakpoint-laptop-small-max-width) {
        & {
          max-width: 35vw;
        }
      }

      // 1200 - 1399
      @media only screen and (min-width: $breakpoint-laptop-large-min-width) and (max-width: $breakpoint-laptop-large-max-width) {
        & {
          max-width: 35vw;
        }
      }

      @media only screen and (min-width: $breakpoint-desktop-min-width) {
        & {
          max-width: 30vw;
        }
      }
    }

    .ea-fluid-container__text {
      color: inherit;
      bottom: unset;
      margin: 0 5%;

      .evi-layout-wide & {
        max-width: none;
        padding-right: 5%;
        padding-left: 5%;
      }

      // 640 - 959
      @media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
        & {
          margin-top: 30px;
        }
      }

      // 960 - 1199
      @media only screen and (min-width: $breakpoint-laptop-small-min-width) and (max-width: $breakpoint-laptop-small-max-width) {
        & {
          top: 7%;
        }
      }

      // 1200 - 1399
      @media only screen and (min-width: $breakpoint-laptop-large-min-width) and (max-width: $breakpoint-laptop-large-max-width) {
        & {
          top: 13%;
        }
      }

      @media only screen and (min-width: $breakpoint-desktop-min-width) {
        & {
          top: 17%;
        }
      }
    }
  }

  .ea-fluid-container__background-image {
    height: 100%;
  }

  .evi-hero-container__arrow {
    display: none;
  }

  .evi-hero-buttons>div {
    @media only screen and (max-width: $breakpoint-laptop-max-width) {
      display: inline-block;
    }

    @media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-laptop-max-width) {
      padding-right: 12px;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    & {
      height: auto !important;
    }

    .ea-fluid-container__content,
    .ea-fluid-container__background-image,
    .ea-fluid-container__background-image img,
    .ea-fluid-container__text {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      height: unset;
    }

    .evi-hero-description {
      max-height: unset;
    }

    .ea-fluid-container__content {
      background: none !important;
      padding-top: 20px;
    }

    .evi-hero-container__arrow {
      display: block;
      position: absolute;
      left: calc(50% - 22px);
      top: -22px;
      border-left: 22px solid transparent;
      border-right: 22px solid transparent;
      border-bottom: 22px solid;
    }

    @media only screen and (max-width: $breakpoint-phone-large-max-width) {
      .evi-hero-background__gradient {
        display: none;
      }

      .ea-fluid-container__content {
        text-align: center;

        .evi-hero-title,
        .evi-hero-subtitle,
        .ea-bodytext,
        .evi-hero-description {
          max-width: unset;
        }
      }

      .evi-hero-logo-image {
        max-width: 80%;
      }
    }

    @media only screen and (min-width: $breakpoint-tablet-min-width) {
      .ea-fluid-container__background-image {
        position: absolute;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-laptop-min-width) and (max-width: $breakpoint-laptop-small-max-width) {
    & {
      margin-bottom: 24px !important;
    }
  }
}
