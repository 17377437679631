.evi-trailer-filter {
    background: var(--neutral300-color);
    color: var(--neutral700-color);
    padding: 3px 12px;
    font-size: 14px;
    border-radius: 5px;
    margin: 0 4px 10px;

    &-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &:hover {
      cursor: pointer;
    }

    &-selected {
      background: var(--button-color);
      color: var(--neutral700-color);
    }
  }
