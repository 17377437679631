.evi-consent-management {
  &--item {
    &:first-of-type {
      margin-top: 1em;
    }

    a {
      color: var(--link-color);
    }
  }
}