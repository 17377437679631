@import '../../styles/_variables.scss';

.evi-description {
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 214px;

    &__open {
        max-height: unset !important;
        -webkit-line-clamp: unset !important;
        transition: all 0.3s ease;
    }

    &__expand {
        color: var(--accent600-color);
        cursor: pointer;
        transition: color 0.3s ease;
        margin-left: 0 !important;
    }
}

.evi-list-element__description {
    margin: 8px 0;

    .evi-description {
        color: var(--neutral700-color);
        -webkit-line-clamp: 3;
        max-height: 72px;
    }
}

@media only screen and (min-width: $breakpoint-laptop-small-min-width) { 
    .evi-description {
        &__expand:hover {
            color: var(--accent800-color);
        }
    }
}


@media only screen and (max-width: $breakpoint-laptop-small-min-width) {
    .evi-description {
        -webkit-line-clamp: 4;
        max-height: 100px;
    }
    
    .evi-list-element__description-expand:hover {
        color: var(--accent800-color);
    }
}

@media only screen and (max-width: $breakpoint-phone-large-max-width) {
    .evi-list-element__description-container>span {
      -webkit-line-clamp: 2;
    }
  }
  
 