.evi-product-separator {
  border: 0px;
  height: 1px;
  background-color: grey;
  width: 100%;
}

.evi-text-block {
  text-align: left;
  &--bold {
    font-weight: 600;
  }
}
