@import '../styles/variables.scss';

.evi-player-big-button {
  font-size: 80px;
}

video::-webkit-media-controls {
  display:none !important;
}

.evi-player-poster {
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .ea-icon--play {
    font-size: 100px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
  }
}

#videoContainer {
  position: relative;
  outline: none;

  .video-js {
    .vjs-settings-button,
    .vjs-chromecast-button {
      display: none;
    }
    .vjs-poster {
      background-size: cover;
      background-position: inherit;
    }
    video[poster]{
      object-fit: cover;
    }
  }

  .clpp-fullscreen,
  &.evi-player-fullscreen {
    height: 100%;
    width: 100%;
    padding: 0;

    video,
    .evi-videoPlayer,
    #videoPlaceholder {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .videoWrapper {
    width: 100%;
    height: 100%;
    z-index: 9;
    justify-content: center;
    display: flex;
    .evi-videoPlayer {
      >video {
        max-width: 100%;
        max-height: 100%;
      }
      &.vjs-fullscreen {
        height: 100% !important;

        video {
          height: 100% !important;
        }
      }
      .clpp-container {
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        background-color: transparent;
        position: relative;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        height: 100%;
      }

      .clpp-hidden {
        display: none;
      }

      .clpp-fill {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .clpp-subtitle-wrapper {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;

        .clpp-subtitle-cues {
          display: block;
          width: 100%;
          background: none !important;
          font-family: "Open Sans", Arial, sans-serif !important;
        }
      }

      .clpp-ima > div {
        position: absolute;
        top: 0;
        left: 0
      }
    }
    .evi-buffering-spinner {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 48px;
      height: 48px;
      border-style: solid;
      border-color: var(--button-color) var(--button-color) transparent var(--button-color);
      border-width: 8px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -webkit-animation: rotate .8s linear infinite;
      animation: rotate .8s linear infinite;
      z-index: 1;
    }
  }

  .evi-player-poster {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: black;

    img {
      width: 100%;
      height: auto;
    }
  }

  .evi-player-controls {
    z-index: 2147483647;
  }

  .vjs-has-started ~ .evi-buffering-spinner {
    display: none;
  }

  .vjs-seeking ~ .evi-buffering-spinner,
  .vjs-waiting ~ .evi-buffering-spinner {
    display: block;
  }

  .evi-next-episode-modal {
    display: block;
    background: rgba(16, 18, 33, .7);
    padding: 25px 25px 5px 25px;
    border: 2px solid #FFF;
    border-radius: 5px;
    position: absolute;
    transition: $player-opacity-transition, $player-visibility-transition;
    width: 500px;
    min-width: 300px;
    margin-right: 10px;
    right: 1%;
    bottom: 85px;
    z-index: 2147483647;
    color: #FFF;
    font-size: 1.5rem;
    line-height: 1.9rem;

    .evi-next-episode-row {
      display: flex;
      margin-bottom: 20px;
    }

    .evi-next-episode-header {
        font-size: 1.8rem;
        font-style: italic;
    }

    .evi-next-episode-text {
        width: 100%;
        margin-left: 20px;
    }

    .evi-next-episode-title {
        margin: 20px 0;
        font-weight: 700;
        font-size: 1.8rem
    }

    .evi-next-episode-description {
      max-height: 135px;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      p {
        margin: 0;
      }
    }

    .evi-next-episode-button-row {
      display: flex;
      margin-bottom: 20px;
      div { width: 48%}
    }
    .ea-button--transparent {
      color: #FFF;
      border-color: #FFF;
    }
    .evi-button--skipcounter {display: none;}
    .ea-button--lightblue {
      width: 100%;
    span {
      span {text-transform: lowercase;}
      }
    }
    &.showModal {
      display: block;
      .evi-button--skipcounter {display: block;}
    }
  }

  @keyframes hoverColor {
    5% { color: white; }
    80% { color: white; }
    100% { color: transparent; }
  }

  .playerErrorMsg {
    position: absolute;
    bottom: 75px; // Leave space for player-controls-bar
    border-radius: 12px;
    z-index: 100;
    display: flex;
    align-items: center;
    right: 0;
    margin: 0 $player-controls-margin-desktop;

    > span {
      margin-right: 16px;
    }

    .ea-button {
      margin-right: 16px;
      border: 2px solid white;
    }
    .ea-icon, .ea-button  {
      color: white;
    }
    .ea-notification__close {
      padding: 0;
      margin: 0;
    }
  }

  .playerErrorMsg.evi-non-fatal-error {
    background: rgba(255, 255, 255, 0.75);
    color: $color-dark-grey;
    border: 2px solid transparent;

    > .ea-button {
      color: $color-dark-grey;
      border: 2px solid $color-dark-grey;
    }

    .ea-icon {
      color: $color-dark-grey;
    }
  }

  #videoPlaceholder {
    position: relative;
    background: black;
    overflow: hidden;
    #video {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    // No need to show VJS errors, since we handle player errors
    .vjs-error .vjs-error-display .vjs-modal-dialog-content,
    // No need to show "X" on error situations
    .vjs-error .vjs-error-display:before,
    // Must not show spinner if video playback has started
    .vjs-has-started .vjs-loading-spinner {
      display: none !important;
    }
  }
}

.play-position-indicator {
  position: relative;
  height: 10px;
  background: rgba(235,235,235,0.4);
  border-bottom-left-radius: $default-border-radius;
  border-bottom-right-radius: $default-border-radius;

  span {
    display: block;
    background: #006dfc;
    height: 10px;
    border-bottom-left-radius: $default-border-radius;
  }
}

.play-position-indicator-overlay-bottom {
  top: calc(100% - 10px);
}

.ea-fgrid__item.ea-fgrid__item--laptop-2 .play-position-indicator {
  margin-top: -20px;
}

// For some reason Safari uses print styles in normal playback, causing controls to be hidden.
// Override print hiding logic to make sure controls are visible
@media print {
  .video-js>:not(.vjs-tech):not(.vjs-poster) {
    visibility: visible;
  }
}


@media screen and (max-width: $breakpoint-laptop-max-width) {
  #videoContainer {
    .evi-next-episode-modal {
      width: 40%;
      border: none;
      min-width: auto;
      bottom: 85px;
      img {
         display: none;
      }
      .evi-next-episode-button-row {
        display: block;
        .ea-button--transparent {width: 100%; margin-bottom: 20px;}
        div {width: 100%;}
      }
      &:after, &:before {
        display: none;
      }
    }
    .playerErrorMsg {
      margin: 0 $player-controls-margin-mobile;
    }
  }
}

@media screen and (max-width: $breakpoint-phone-large-max-width) {
  #videoContainer {
    .evi-next-episode-modal {
      width: 95%;
      right: auto;
    }

    .playerErrorMsg {
      bottom: 55px;
    }

    .evi-player-big-button {
      font-size: 40px;
    }
  }
}
