@import '../../../../styles/variables';

.evi-channel-tooltip {
  &__schedule {
    background-color: $color-white-smoke;
    border: 1px solid $color-smoke;
    opacity: .9;
    margin: 5px 0 10px 0;
    padding: 8px;
    border-radius: $default-border-radius;
  }

  &__ongoing-program,
  &__upcoming-programs {
    &-label {
      font-weight: bold;
    }
  }

  &__close {
    @media only screen and (min-width: $breakpoint-tablet-min-width) { 
      display: none;
    }
  }
}
