.evi-purchase-wizard-default-thank-you {
  &__get-app {
    &-heading {
      padding: 0 0 16px;
    }

    &-description {
      margin: 0 0 27px;
    }
  }

  &__close-button {
    margin: 80px 0 0 0;
  }
}
