@import '../../../styles/variables.scss';

.evi-password-input-renderer {
  &--weak &__password-strength {
    color: $color-orange;
  }

  &--mediocre &__password-strength {
    color: var(--link-color);
  }

  &--strong &__password-strength {
    color: $color-green;
  }
}
