.evi-non-logIn-user-purchase-options {
  padding-top: 30px;

  &__title {
    padding: 0 0 16px;
    flex: 1;
  }

  &__description {
    margin: 0;
    padding: 0 0 24px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    max-width: 310px;

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      >* {
        width: 100%;
      }
    }
  }
}