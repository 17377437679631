@import 'src/styles/variables';

.evi-icon {
  will-change: opacity;
  transition: opacity 0.3s ease;

  &--is-loading {
    opacity: 0;
  }
  
  &--is-loaded {
    opacity: 1;
    background-color: $color-white;
  }
}
