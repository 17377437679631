@import 'src/styles/variables';

.evi-product-card-ribbon {
  position: absolute;
  left: 4px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 100px; height: 100px;
  text-align: right;

  span {
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 26px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 108px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#1C7BF8 0%, #0068F0 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 17px; left: -24px;
  }

  span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #091aaf;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #091aaf;
  }

  span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #091aaf;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #091aaf;
  }

  &--info {
    span {
      background: linear-gradient($color-notification-info 0%, darken($color-notification-info, 5%) 100%);
    }

    span::before {
      border-top-color: darken($color-notification-info, 10%);
      border-left-color: darken($color-notification-info, 10%);
    }
    
    span::after {
      border-top-color: darken($color-notification-info, 10%);
      border-right-color: darken($color-notification-info, 10%);
    }
  }

  &--success {
    span {
      background: linear-gradient($color-notification-success 0%, darken($color-notification-success, 5%) 100%);
    }

    span::before {
      border-top-color: darken($color-notification-success, 10%);
      border-left-color: darken($color-notification-success, 10%);
    }
    
    span::after {
      border-top-color: darken($color-notification-success, 10%);
      border-right-color: darken($color-notification-success, 10%);
    }
  }

  &--warning {
    span {
      background: linear-gradient($color-notification-warning 0%, darken($color-notification-warning, 5%) 100%);
    }

    span::before {
      border-top-color: darken($color-notification-warning, 10%);
      border-left-color: darken($color-notification-warning, 10%);
    }
    
    span::after {
      border-top-color: darken($color-notification-warning, 10%);
      border-right-color: darken($color-notification-warning, 10%);
    }
  }

  &--danger,
  &--error {
    span {
      background: linear-gradient(#ED5D5D 0%, #EC5C5C 100%);
    }

    span::before {
      border-top-color: #922728;
      border-left-color: #922728;
    }

    span::after {
      border-top-color: #922728;
      border-right-color: #922728;
    }
  }
}
