@import '../../styles/variables';
@import 'src/styles/mixins';

.evi-accordion-header {
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  outline-offset: 1px;

  &::before {
    cursor: none;
    visibility: hidden;
    display: none;
  }

  &::after {
    content: $elisaicon-arrow-down;
    margin-left: 15px;
    font-size: 25px;
    font-family: $global-font-icon;
    color: $color-linkblue;
    line-height: $global-baseline * 3;
    will-change: transform;
    transition: transform 0.3s ease-in-out;
    -webkit-font-smoothing: antialiased;
  }

  &--expanded {
    &::after {
      transform: scaleY(-1);
    }
  }

  @include accessibility-not-enabled {
    outline: none;
  }
}

.evi-accordion-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding-right: 0;
  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    & {
      max-width: 80%;
    }
  }
  @media only screen and (max-width: $breakpoint-phone-large-max-width) {
    & {
      max-width: 90%;
    }
  }
  &:hover {
    cursor: initial;
  }
}

.ea-accordion__element:first-child {
  border-top: 1px solid #e0e0e1
}
/* Specific class to remove the border */
.custom-accordion .ea-accordion__element:first-child {
  border-top: none; /* Remove the top border for the custom accordion */
}
.custom-accordion button {
  display: block !important; // overrides the flex property of parent class button
}

/* Cursor fixes */

.evi-accordion--content-toggle .ea-accordion__content,
.ea-accordion__header:before {
  cursor: pointer;
}

/* Modifiers that were removed from pattern library */

.ea-accordion {
  &--tight &__element {
    padding: 12px 0;
  }

  &--arrow-left {
    $arrow-spacer: 50px;
    .ea-accordion__header {
      margin-right: 0;
      margin-left: $arrow-spacer;

      &:before {
        right: auto;
        left: 16px;
      }
    }

    .ea-accordion__content {
      padding-left: $arrow-spacer;
    }
  }
}
