.evi-product-payment-options {
  overflow: visible;

  &__notification {
    background: var(--neutral100-color);
    border: 1px solid var(--neutral300-color);
    color: var(--alternative-color);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 16px 16px 21px;
    margin: 0 0 23px;

    a {
      color: var(--alternative-color);
      text-decoration: underline;
    }
  }
}
