@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-add-to-calendar {
  color: $color-dark-grey;
  
  &__list {
    display: flex;
    flex-direction: column;
  }

  a {
    color: inherit;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .social-media-share-overlay--content & {
      color: $color-dark-grey;
    }
  }

  .calendar-icon {
    display: inline-block;
    height: 100%;
    max-height: 18px;
    max-width: 18px;
    margin: 0 8px -2px 0;
  }

  .ea-modal__content & {
    margin: 16px 0 0;
  }
}
