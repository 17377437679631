@import '../../../../styles/variables.scss';
.evi-activation-toast {
  display: flex;
  align-items: center;

  img {
    margin-right: 24px;
    height: auto;
    width: auto;
    max-height: 24px;
    min-width: 24px;
  }

  button,
  form {
    margin-left: 32px;
  }

  > span {
    margin: 0;
    
  }

  &--description {
    display: flex;
    flex-direction: column;

    a {
      font-weight: bold;
    }
  }
}
.disney-activation-toast img {
  margin-right: 16px;
  max-width: 60px;
  max-height: unset; /* Removes inherited max-height */
  min-width: unset;
}

.evi-activation-toast--modal {
  display: flex;
  flex-direction: column;

  img {
    width: 100px;
    margin-bottom: 16px;
  }
}

.evi-custom-notification {
  padding: 17px 33px 17px 38px;
}

@media only screen and (max-width: $breakpoint-phone-large-max-width) {
  .evi-activation-toast {
    img {
      margin-right: 16px;
    }

    span {
      font-size: 18px !important;
    }    
  }

  .evi-custom-notification {
    padding: 10px 10px 10px 20px;
  }
}

@media only screen and (max-width: $breakpoint-tablet-max-width) {
  .evi-activation-toast {
    button {
      margin-left: auto;
    }
  }

  .evi-custom-notification {
    padding: 10px 20px 10px 20px;
  }
}