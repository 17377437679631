.evi-series-promotion {
  max-width: 700px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 1400px) {
    & {
      max-width: 70%;
    }
  }

  p, h1, .evi-action-button-group {
    text-align: center;

    .ea-pad {
      padding: 0;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }
}

.evi-promotion-video {
  margin-bottom: 18px;

  #videoPlaceholder, .evi-player-poster {
    box-shadow: 2px 4px 40px rgba(0,0,0,0.9);
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    .clpp-container {position: absolute;}

    video, .video-js {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &--placeholder {
    width: 100%;
    height: 100%;
  }
}

.evi-series-promotion-description {
  font-size: 18px;
  font-weight: 600;
  margin: 2px 0 18px 0;

  p {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }
}

.evi-series-promotion-plot {
  margin-top: 12px;

  p:first-child {
    margin-top: 0;
  }
}
