@import '../../styles/mixins';
@import '../../styles/variables';

.evi-cast-card {
  > a {
    display: block;
    color: inherit;
  }

  &__profile {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    background-color: var(--neutral100-color);
    background-image: url('/assets/static/placeholders/cast_placeholder.png');
    background-position: center;
    background-size: auto 101%;
    border-radius: $default-border-radius;
    z-index: 1;

    &-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        will-change: opacity;
        transition: opacity 0.3s ease;
      }
    }
  }

  &__name, &__role {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--neutral700-color);
    margin: 2px 0 0;

    &:nth-child(3) {
      margin-top: 0;
    }

    @include for-phone-large-down {
      font-size: 14px;
    }
  }

  &__role {
    font-style: italic;
  }

  &--default &__profile {
    padding: $watchable-card-height-default 0 0 0;
  }

  &--landscape &__profile {
    padding: $watchable-card-height-landscape 0 0 0;
  }

  &--giant &__profile {
    padding: $watchable-card-height-giant 0 0 0;
  }

  &--cast &__profile {
    padding: $watchable-card-height-cast 0 0 0;
  }

  &--is-loading &__profile-image img {
    opacity: 0;
  }

  &--is-loaded &__profile-image img {
    opacity: 1;
  }

  &--has-error &__profile-image img {
    display: none;
  }

  .evi-layout-wide & {
    &__name, &__role {
      @include for-desktop-up {
        font-size: 0.9vw;
        margin-top: 0.25vw;

        &:nth-child(3) {
          margin-top: 0.05vw;
        }
      }
    }
  }
}


