.evi-app-logos {
    max-width: 310px;
    margin: 5px 0 0 20px;
  }

  .app-logo-name {
    margin-left: 10px;
    color: black;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

  .evi-app-logos-list {
    display: flex;
    list-style-type: none; /* Removes bullets */
    
      &__logo{
        display: flex;
        flex-flow: wrap;
        width: 50%;
        max-width: 100%;
        padding: 0 12px 8px;
    
        a {
          display: flex;
        }
    
        img {
          display: flex;
        }
      }
  }

  .logo-height {
    img{
      height: 40px !important;
    }
    
  }
  
  