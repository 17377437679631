@import '../../../styles/_variables.scss';

.evi-registration-form {
  width: 100%;
  max-width: 400px;

  form {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__heading {
    padding-bottom: 27px;
  }

  &__help-text {
    margin: 0;
    padding-bottom: 12px;
    font-size: 13px;
    color: $color-grey;
  }

  &__elisa-id-notification {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
  }

  &__billing-address {
    h2 {
      padding-bottom: 27px;
    }
  }

  &__terms {
    margin-top: 30px;
    padding-top: 6px;
    border-top: 1px solid #D0D0D1;

    .ea-input {
      padding-bottom: 0;
    }

    .ea-checkbox label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    .ea-bodytext, .ea-input__message {
      margin-left: 42px;
    }

    .ea-input__message {
      padding-bottom: 6px;
    }
  }

  .ea-fgrid__item {
    overflow: visible;
  }

  .ea-fgrid.ea-fgrid--gutter {
    margin-left: -20px;
  }

  .evi-registration-form-input {
    padding-top: 15px;
  }

  .evi-fgrid__item--flex-auto {
    flex-basis: auto;
    flex-grow: 0;
  }

  #registration-agreement {
    display: inline-block;
    font: inherit;

    label {
      align-items: center;
    }
  }

  .evi-password-input-renderer__password-visibility:hover {
    cursor: pointer;
  }
}
