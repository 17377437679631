@import '../../../styles/variables.scss';

.evi-event-vertical-list-skeleton {
  &__item {
    width: 100%;
    height: 80px;
    display: flex;
    margin: 0 0 30px 0;
    border-radius: 2px;
    background-color: var(--neutral100-color);
    box-shadow: 1px 2px 20px #080809;
  }
  &__title {
    margin: 2% 0;
    height: 36px;
    width: 18%;
    background-color: var(--neutral100-color);
    box-shadow: 1px 2px 20px #080809;
  }
  &__cover {
    background-color: var(--neutral100-color);
  }

  @media only screen and (max-width: $breakpoint-phone-large-max-width) {
    &__item {
      height: 48px;
      margin: 0 0 17px 0;
    }
    &__title {
      margin: 2% 0;
      height: 30px;
      width: 45%;
    }
  }
}
