@import '../../../styles/mixins.scss';

.evi-identification-providers {
  ul {
    transition: opacity 0.3s ease;
    display: flex;
    flex-flow: wrap;
    margin: 0 -4px;
  }

  &-description {
    margin: 0 0 35px;
  }

  &--disabled ul {
    opacity: 0.2;
  }

  &__provider {
    position: relative;
    width: 25%;
    margin: 0 0 10px;

    @include for-phone-large-down {
      width: 50%;
    }

    &:before {
      display: block;
      padding-bottom: 60%;
      content: "";
    }

    &-button {
      position: absolute;
      display: flex;
      cursor: pointer;
      align-items: center;
      border: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
      margin: 0 auto;
      background-color: rgba(224, 224, 225, 0.25);
      width: calc(100% - 8px);
      user-select: none;

      > img {
        width: 100%;
      }
    }
  }
}
