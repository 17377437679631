.evi-conditions {
  &__link {
    margin-left: 4px;
  }

  &--conditions-as-label {
    .ea-bodytext {
      margin-top: 0;
    }
  }

  &__conditions {
    font-size: 14px;
    color: var(--neutral700-color);

    > p {
      margin: 0 0 20px;
      font-size: 14px;
    }

    a {
      color: var(--link-color);
      text-decoration: underline;
    }
  }
}
