
.evi-stripe--event {
  .evi-infinite-list {
    margin-right: 0;
  }
  .evi-eventcard-wrapper {
    position: relative;
    width: $eventcard-width-desktop;
    height: $eventcard-height-desktop;
    background: #e0e0e1;
    border-radius: $default-border-radius;
  }
}

@media only screen and (max-width: $breakpoint-tablet-max-width) {
  .evi-eventcard-error {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    .ea-button {
      width: 100%;
      zoom: 0.7;
    }
  }
  .evi-eventcard__text-series {
    margin: 0 !important;
  }
}

@media only screen and (max-width: $breakpoint-phone-max-width) {
  .evi-eventcard-indicator {
    font-size: 1.1rem;
    padding: 0 5px;
    height: 26px;
    line-height: 29px;
  }
  .evi-eventcard {
    .evi-eventcard__text {
      padding: 2px 5px 0 5px;
      width: $eventcard-width-phone;
    }
    .ea-event-thumbnail__description {
      width: $eventcard-width-phone;
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }
  .slick-list, .evi-infinite-list {
    .evi-eventcard--image {
      height: $eventcard-height-phone !important;
    }
    .evi-eventcard-wrapper.evi-eventcard-background {
      height: $eventcard-height-phone;
      width: $eventcard-width-phone;
    }
    .evi-eventcard-error {
      width: $eventcard-width-phone;
      height: 153px;
      font-size: 1.2rem;
    }
    .evi-list-indicator {
      display: block;
      width: 100%;
      font-size: 1.2rem;
    }
  }
}
@media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
  .evi-eventcard-indicator {
    font-size: 1.4rem;
    padding: 0 7px;
  }
  .evi-eventcard {
    .evi-eventcard__text {
      padding: 3px 7px 0 7px;
      width: $eventcard-width-tablet;
    }
    .ea-event-thumbnail__description {
      width: $eventcard-width-tablet;
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
  .slick-list, .evi-infinite-list {
    .evi-eventcard--image {
      height: $eventcard-height-tablet !important;
    }
    .evi-eventcard-wrapper.evi-eventcard-background {
      height: $eventcard-height-tablet;
      width: $eventcard-width-tablet;
    }
    .evi-eventcard-error {
      width: $eventcard-width-tablet !important;
      height: 173px;
      font-size: 1.4rem;
    }
    .evi-list-indicator {
      display: block;
      width: 100% !important;
      font-size: 1.2rem;
    }
  }
}
