@import '../../../styles/variables.scss';

.purchase-email-info{
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $color-dark-smoke;

  &__email-title {
    display: flex;
    width: 100%;
    padding-bottom: 10px;

    &-email {
      flex: 1;
    }
  }

  &__description {
    padding-bottom: 10px;
    width: 90%;
  }
  
  &__label {
    width: 90%;
    margin-top: -5px;
    padding: 0 35px 0 0;
    font-size: 12px;
    line-height: 16px;
    color: #70707A;
  }
}
