@use "sass:math";
@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-stripe-item {
  display: inline-block;
  padding: 0 math.div($slider-gutter-width, 2);
  vertical-align: top;

  // Don't allow card image fades when the cards are hidden.
  &:not(&--active) {
    .evi-watchable-card,
    .evi-cast-card,
    .evi-event-card {
      &__cover-image img {
        transition: none;
      }
    }
  }
}
