.evi-checkbox {
  color: var(--neutral700-color);

  label {
    z-index: 1;
  }

  .ea-checkbox {
    color: var(--neutral700-color);
  }
}
