@import '../../../styles/variables.scss';

.evi-sport-series-information {
  img {
    max-height: 36rem;
    width: auto;
    height: auto;
  }
  .ea-marketing-block__content {
    .ea-marketing-block__article {
      .ea-h1--thick {
        font-family: "Jost", sans-serif
      }
    }
    min-height: 36rem;
  }
}

.evi-series-logo-align-middle {
  max-width: 100%;
}

@media only screen and (max-width: $breakpoint-tablet-max-width) {
  .evi-sport-series-information {
    .ea-marketing-block__content {
      min-height: initial !important;
    }
    .ea-marketing-block__aside {
      margin-bottom: 0;
    }
  }
}

