@import '../../../styles/_variables.scss';

.evi-play-next {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ea-button--icon:before {
    margin-top: -12px;
    left: 20px;
  }

  @media screen and (max-width: $breakpoint-phone-large-max-width) {
    .ea-button--icon:before {
      margin-top: -8px;
    }
  }
}