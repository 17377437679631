@import '../../../styles/variables.scss';

.evi-purchase-wizard-crm-product-info {
  border-bottom: 1px solid $color-dark-smoke;

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
  }

  &__name {
    flex: 1;
  }

  &__icon {
    width: 60px;
    height: 60px;
    padding: 3px 0 0 2px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px #E8E8EA;
    border-radius: 5px;

    > img {
      width: 55px;
      height: 55px;
    }
  }

  &__description {
    margin: 0;
  }

  &__terms {
    margin: 5px 0 0 0;
  }

  &__show-more {
    &-content {
      margin: 0;
    }

    &-link {
      cursor: pointer;
    }

    &--is-collapsed {
      display: flex;
      flex-direction: row;
    }

    &--is-collapsed &-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }

    &--is-collapsed &-link {
      padding: 0 0 0 5px;
    }
  }
}

.no-background {
  &__icon {
    background: none;
    box-shadow: none;
  }
}

