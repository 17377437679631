@use "sass:math";
@import '../../../styles/mixins';
@import '../../../styles/variables';

.evi-stripe-skeleton {
  padding: 0 5%;
  position: relative;
  z-index: 1;

  &__items {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 math.div(-$slider-gutter-width, 2);
  }

  &__item {
    display: inline-block;
    padding: 0 math.div($slider-gutter-width, 2) 10px;
  }

  .evi-layout-wide & {
    padding: 0;

    &__items {
      padding: 0 5%;
    }

    @include for-desktop-up {
      &__title, &__secondary-title {
        height: 0.9vw;
        margin-top: 0.5vw;
      }
    }
  }
}


