@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.evi-watchable-purchase-options {
  padding: 0 50px;

  @include for-phone-large-down {
    padding: 0 25px;
  }

  &__header {
    padding-bottom: 30px;
    margin: 0 0 21px 0;
    border-bottom: 1px solid $color-dark-smoke;
  }

  &__header-row {
    display: flex;
    align-items: center;
  }

  &__title {
    flex: 1;
  }

  &__subtitle {
    margin: 0;
  }

  &__cover {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 1px 2px 10px #E8E8EA;
    border-radius: 5px;
    overflow: hidden;

    > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  &__coupons {
    padding: 0 0 35px;
  }
}
