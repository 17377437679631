@import '_variables.scss';
@import '_mixins.scss';
@import './carousel/common.scss';
@import 'listElement.scss';
@import 'player.scss';
@import 'titleView.scss';
@import 'smartLogin.scss';
@import 'utils.scss';
@import 'edsOverrides.scss';

:root {
  --neutral-color: #{$theme-default-neutral-color}; // Background color
  --highlight-color: #{$theme-default-highlight-color};
  --neutral100-color: #{$theme-default-neutral100-color}; // Accent background color
  --neutral200-color: #{$theme-default-neutral200-color};
  --neutral300-color: #{$theme-default-neutral300-color};
  --neutral400-color: #{$theme-default-neutral400-color}; // Line color
  --neutral500-color: #{$theme-default-neutral500-color};
  --neutral600-color: #{$theme-default-neutral600-color};
  --neutral700-color: #{$theme-default-neutral700-color}; // Text color
  --neutral800-color: #{$theme-default-neutral800-color};
  --accent400-color: #{$theme-default-accent400-color};
  --accent600-color: #{$theme-default-accent600-color};
  --accent800-color: #{$theme-default-accent800-color};
  --status600-color: #{$theme-default-status600-color};
  --button-color: #{$theme-default-button-color}; // Interactive element color
  --link-color: #{$theme-default-link-color};
  --shadow-color: #{$theme-default-shadow-color};
  --background-image: none;
  --logo: none;
}

html,
body {
  overflow-x: hidden;
  color: var(--neutral700-color);
}

body {
  @include useTheme();
}

a {
  color: var(--link-color);
  text-decoration: none;
}
 
#viihdeonline-react-app-container,
#viihdeonline-react-modal-container {
  h1, h2, h3, h4, h5 .ea-h1, .ea-h2, .ea-h3, .ea-h4, .ea-h5 {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    color: inherit;
    word-break: break-word;
  }

  h1, .ea-h1 {
    font-size: 30px;
    line-height: 36px;

    &.ea-h1--thick {
      font-family: "Jost", sans-serif;
      font-weight: 500 !important; // Using the Verlag font it would be 900, but for the font-face 500 seems to be the correct one
      text-transform: uppercase;
    }
  }

  h2, .ea-h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h3, .ea-h3 {
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;

    &.ea-h3--thick {
      font-family: "Jost", sans-serif;
      font-weight: 500;
      letter-spacing: .028em;
    }
  }

  h4, .ea-h4 {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 22px !important;
    line-height: 24px;
  }

  h5, .ea-h5 {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .ds-price-content-number {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;
  }
  .ds-price-unit {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
  
}


a .ea-icon {
  cursor: pointer;
}

#viihdeonline-react-app-container,
#viihdeonline-react-modal-container {
  .ea-button--light {
    color: inherit !important;
    background-color: inherit !important;
    border-color: currentColor !important;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }

  .ea-search__show-all .ea-button--light {
    background-color: white !important;
  }
}

#viihdeonline-react-app-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#viihdeonline-react-app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  &>.ea-snapper-fluid-container {
    width: 100%;
  }
}

.ea-snapper-fluid-container {
  height: auto;
}

.evi-non-wrapping-inline-block {
  white-space: nowrap;
  display: inline-block;
}

.evi-error-text {
  color: #EE4444;
}

.evi-right-block {
  text-align: right;
}

.evi-content-divider {
  border-bottom: 1px solid #8c8c8c;
  padding-bottom: 10px;
  clear: both;
}

.evi-bullet-collection {
  margin-top: 10px;
  .ea-fgrid {
    margin: 0 5% 20px calc(5% - 10px);
    .ea-fgrid__item {
      padding-top: 10px;
      padding-left: 10px;
    }
  }
}

.evi-bullet-block {
  font-style: normal;
  text-transform: uppercase;

  display: block;
  width: 100%;
  height: fit-content;
  position: relative;

  img {
    width: 100%;
    border-radius: $default-border-radius;
  }
  .evi-bullet-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    color: #FFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-weight: normal;
    font-weight: 700;
    p {margin: 0 0 5px 0;}
    .evi-bullet-title {
      font-family: "Jost",sans-serif;
      font-size: 21px;
      font-weight: 700;
    }
  }
}

.SocialMediaShareButton {
  display: inline-block;
  cursor: pointer;
  margin: 0 10px;
  .ea-icon {
    font-size: 40px
  }
}

.social-media-share {
  display: flex;
  cursor: pointer;
  margin: 0;
  .ea-icon {
    font-size: 25px;
    margin-right: 8px;
  }
  > div {
    margin: 0;
    display: flex;
  }
}

.evi-event-buttons {
  text-transform: uppercase;
  cursor: pointer;
  .evi-favorite-link{
      gap: 15px;
      span{
        margin-top: 0px;
      }
  }
  

  .ea-accordion {
    margin: 1.2rem 0 1.2rem 0;
  }
  .ea-accordion__element {
    color: inherit;
    border-top: none;
    padding: 0;
  }
  .ea-accordion__header {
    line-height: 1.5em;
    padding: 0;
    text-transform: uppercase;
  }
  .ea-accordion__content {
    padding-bottom: 0;
  }
  .ea-accordion-content-extended {
    margin-top: 1em;
  }
  .ea-accordion__header:before {
    display: none;
  }
  .evi-accordion-header {
    justify-content: flex-start;
    margin: 0;
    &::after {
      display: none;
    }
  }
  .evi-accordion-content {
    margin: 0;
  }
  .ea-fgrid {
    padding: 0;
    justify-content: flex-start;
    text-align: left;
    display: block;
  }
}
.evi-margin-top-bottom {
  margin: 1em 0 1em 0;
}
.evi-margin-top {
  margin-top: 1em;
}

// Bullet on mobili
@media screen and (min-width: 640px) and (max-width: 959px) {
  .evi-bullet-block {
    .evi-bullet-text {
      font-size: 13px;
      line-height: 16px;
      .evi-bullet-title {
        font-size: 16px;
      }
    }
  }
}

// Override H1 size on large resolutions to set maximun size 40px.
@media screen and (min-width: 600px) {
  h1.ea-h1.evi-h1-small {
    font-size: 4rem;
    line-height: 1.20em;
  }
}

// This style is needed when react app is combined with the old app
#viihdeonline-react-app-root {
  font-family: "Open Sans", Arial,sans-serif;
  font-size: 16px;
}

// ea-combobox bug workaround: z-index is set only for open combobox, not while animating the closing effect.
.ea-combobox .ea-combobox__options {
  // Using z-index 9 instead of standard 20 to make sure comboboxes aren't shown on to of navigations
  z-index: 9;
}

body.vuokraamo #content,
body.aitio #content {
  overflow: visible;
}

// Custom aspect ratios for elisaviihde, used with hero
@media only screen and (min-width: 960px) {
  .ea-fgrid__item--laptop-ratio-4-1 {
    display: block
  }
  .ea-fgrid__item--laptop-ratio-4-1:before {
    display: block;
    content: "";
    height: 0;
    padding-top: 25%
  }
}
@media only screen and (min-width: 640px) {
  .ea-fgrid__item--tablet-ratio-4-1 {
    display: block
  }
  .ea-fgrid__item--tablet-ratio-4-1:before {
    display: block;
    content: "";
    height: 0;
    padding-top: 25%
  }
}

@media screen and (max-width: $breakpoint-laptop-min-width) {
  .evi-background {
    height: 40vh;
  }
}

.evi-green-text {
  color: #00a950;
}
.evi-blue-text {
  color: #0019af;
}
.evi-orange-text {
  color: #ff6400;
}

.evi-white-text {
  color: #ffffff
}

#viihdeonline-react-modal-container {
  .ea-modal {
    z-index: 81;
  }

  .ea-modal__heading{
    font-family: "Jost Medium", sans-serif;
    line-height: 32px;
    margin-left: 24px;
    margin-top: 40px;
    font-size: 26px;
  }
  @media only screen and (min-width: 640px) {
    .ea-modal {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ea-modal__content {
      height: auto;
      top: auto;
      position: relative;
    }
  }

  @media only screen and (max-width: 640px){
    .ea-modal__content{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 320px;
      line-height: 16px;
      .ea-modal__heading{
        line-height: 28px;
        font-size:24px;
      }
      .evi-modal__box__share {
        padding: 16px 0px 40px 24px;
      }
    }
  }
}
  @media only screen and (max-width: 320px){
    .evi-modal__content_share{
      position: absolute;
      top: 40%;
      left: 7%;
      width: 280px;
      line-height: 16px;
      .ea-modal__heading{
        line-height: 28px;
        font-size:24px;
      }
      .evi-modal__box__share {
        padding: 16px 0px 40px 24px;
      }
    }
}

.evi-event-background {
  background-size: 90% auto !important;
}


@media (max-width: 959px) {
  .evi-event-header {
    margin-top: 60%;
  }
}

@media (min-width: 960px ) and (max-width:1399px){
  .evi-event-header {
    margin-top: 75%;
  }
}

@media (min-width: 1400px) {
  .evi-event-header {
    margin-top: 85%;
  }
}

.evi-background {
  position: relative;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1; 

  &__alt {
    background-size: 100vw;
    background-repeat: no-repeat;
  }
}
.evi-background-blur:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
}


img.evi-disabled {
  filter: grayscale(100%);
}

.evi-fgrid--small-gutter .ea-fgrid__item {
  margin-top: 4px;
}

.evi-no-bottom-margin {
  margin-bottom: 0 !important;
}

.evi-layout-wide {
  .ea-snapper-fluid-container {
    max-width: none;
  }
}

.ea-input--invalid {
  .ea-input__message {
    a {
      color: $color-error;
      text-decoration: underline;
    }
  }
}

.evi-ribbon {
  height: 120px;
  width: 120px;
  overflow: hidden;
  text-align: right;
  position: absolute;
  top: -5px;
  right: -3px;
  pointer-events: none;
  z-index: 1;

  span {
    display: block;
    width: 100%;
    background-color: #006dfc;
    font-size: 12px;
    font-weight: 600;
    color: #f2f5fb;
    line-height: 20px;
    text-align: center;
    padding: 1px 0 2px;
    position: absolute;
    top: 0;
    right: -3px;
    transform: translateY(100%) translateX(20%) rotate(45deg);
    box-shadow: 0 3px 10px -5px #000;

    &:before,
    &:after {
      display: block;
      content: '';
      border: 3px solid transparent;
      border-top-color: #0454bd;
      position: absolute;
      top: 100%;
      z-index: -1;
    }

    &:before {
      border-left-color: #0454bd;
      left: 0;
    }

    &:after {
      border-right-color: #0454bd;
      right: 0;
    }
  }

  @include for-phone-large-down {
    top: 0;
    right: 0;

    span {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  @media (max-width: 250px) {
    span {
      font-size: 10px;
      right: 0;
      padding-left: 25px;
      transform: translateY(15%) translateX(10%) rotate(25deg);
    }
  }
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {

  .ea-navbar {
    padding: 0 24px !important;
  }  
  .ea-navbar__tools,
  .evi-navbar-links {
      padding-inline-start: 10px !important;
  }
}

@media only screen and (min-width: 960px) {
  .ea-header__dropdown {
    max-height: calc(100vh - 80px) !important;
    overflow-y: auto !important;
    
  }
  body.evi-prevent-scrolling {
    position: static !important;
    height: auto !important;
    overflow-y: auto !important;
  }
}

.ea-header__dropdown1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #2c2d41;
}

.ea-header__dropdown::-webkit-scrollbar
{
	width: 5px;
	background-color: #2c2d41;
}

.ea-header__dropdown::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

