.evi-reset-password {
  width: 100%;
  max-width: 400px;
  margin: 50px auto;

  &__form {
    width: 100%;
    max-width: 400px;

    &__heading {
      padding-bottom: 27px;
    }

    form {
      width: 100%;
      padding: 20px 0;

      .evi-input {
        padding-top: 15px;
      }

      .evi-password-input-renderer__password-visibility:hover {
        cursor: pointer;
      }
    }

    &__errors {
      p:first-child {
        margin-top: 0;
      }
    }
  }
}
