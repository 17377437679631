@import 'src/styles/variables';
@import 'src/styles/mixins';

.evi-view--productPage {
  min-height: 350px;

  .evi-productPage-description {
    margin-top: 0;
  }
}
.evi-payable-content__filter-top {
  color: $color-white;
  padding-top: 30px;
  padding-bottom: 30px;
  background: linear-gradient(180deg, $theme-default-neutral100-color 0%, $theme-default-neutral-color 100%);
  box-shadow: 0px -15px 15px 15px $theme-default-neutral-color;
}
.evi-payable-content {
  &--shadow {
    -webkit-box-shadow: inset 0px 20px 15px -20px $theme-default-neutral-color;
    box-shadow: inset 0px 20px 15px -20px $theme-default-neutral-color;
  }

  &__background {
    width: 1500px;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    opacity: 0.2;
    transform: translateX(-50%);
  }

  &__empty {
    .ea-context-notification {
      width: 100%;
      margin: 0 5px;
    }
  }

  .ea-fgrid--gutter {
    margin: 0 calc(5% - 10px) 20px;
  }

  &__items {
    opacity: 1;
    visibility: visible;
    transition: 0.5s ease;

    &.hidden {
      transition-duration: 0s;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__filter {
    padding: 90px 0 40px;
    position: relative;

    .evi-filter {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;

      &__item {
        width: 130px;
        padding: 5px;
        cursor: pointer;
        color: var(--neutral700-color);

        @include accessibility-not-enabled {
          outline: none;
        }

        &--selected, &:hover {
          color: var(--button-color);
          transition: color 0.3s ease;

          .evi-filter--icon {
            svg {
              stroke: var(--button-color);
              transition: stroke 0.3s ease;
            }
          }
        }

        &--selected {
          font-weight: bold;
        }
      }

      &--icon {
        svg {
          width: 40px;
          height: 40px;
          stroke: var(--neutral700-color);

          path {
            stroke-width: 1;
          }
        }
      }

      &-center {
        display: flex;
        text-align: center;
        padding: 10px 10px 0 10px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    &__filter {
      padding-top: 0;
      padding-bottom: 0;

      &.evi-payable-content--shadow {
        padding-top: 48px;
      }
    }

    &__background {
      display: none;
    }
  }
}

@media only screen and (max-width: $breakpoint-phone-large-max-width) {
  .evi-filter__item {
    font-size: 12px;
    line-height: 16px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.evi-badge {
  background: #FFF;
  div {
    background: #FFF;
    padding: 0px 10px;
    border-radius: 4px;
    border: 2px solid #FFF;
    font-weight: 400;
    text-align: center;
  };
  display: inline-block;
  color: $color-dark-grey;
  box-shadow: 1px 2px 10px 0px $color-smoke;
  font-size: 1.3rem;
  margin: 6px 6px 12px 6px;
  padding: 0;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;
  min-width: 80px;
  &.ea-badge--highlight {
    color: $color-linkblue;
    div {
      border-color: $color-linkblue;
      font-weight: 600;
    }
  }
}

.evi-channel-badge {
  display: inline-block;
}

.usable-platforms > div {
  display: inline-block;
  line-height: 0;
  vertical-align: bottom;
}


.usable-platforms-header {
  margin-right: 3px;
  display: inline-block;
}

.evi-usable-platform {
  cursor: pointer;
  margin: 0 4px;
  .evi-platform-mobile {
    margin: 0 -7px;
  }
  svg {
    width: 30px;
    height: 30px;
    fill: none;
  }
  .stroke {stroke: $color-dark-smoke;}
  .fill {fill: $color-dark-smoke;}
  &.selected {
    .stroke {stroke: $color-linkblue;}
    .fill {fill: $color-linkblue;}
  }
}

.evi-platform-tooltip {
  max-width: 300px;
  font-size: 13px !important;
  line-height: 2.4rem;
  padding: 8px 21px !important;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: $default-border-radius;
  margin-top: -15px !important;
  word-wrap: break-word;
  &.show {
    opacity: 1;
  }
}

.evi-color-container--light-smoke {
  background: $color-light-smoke;
}

.evi-card__content-paragraph {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-bottom: 20px;
}

.evi-selectlist-pricelist {
  display: flex;
  align-items: stretch;
  li {
    background-color: $color-light-smoke;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    &.selected {
      font-weight: bold;
      color: $color-linkblue;
      border-color: $color-linkblue;
    }
  }
  .ea-price-text__price {
    font-size: 2.1rem;
  }
  .ea-price-text__unit {
    font-size: 1.8rem;
  }
  .ea-disclaimertext {
    font-weight: normal;
  }
}

.evi-product-list-header {
  a {color: $color-linkblue;}
  a:after {content: ' \000BB'}
  a:hover {cursor: pointer;}
}

.evi-product-card-need-netpvr {
  background: $color-secondary-blue;
  margin: -24px -40px 10px -40px;
  padding: 5px 40px 5px 40px;
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  .ea-icon--information {
    font-size: 18px;
    float: left;
    margin: 3px 5px 0 -29px;
  }
}

.evi-product-card {
  margin: 0 10px !important;
  border-radius: 4px;
  background: $color-white;
  color: $color-dark-grey;
  box-shadow: 1px 2px 10px 0px $color-smoke;

  h3.ea-h3 {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
    min-height: 80px;
    span {
      flex-grow: 1;
      align-self: center;
    }
    svg,
    img {
      margin-left: 10px;
      width: 80px;
      height: 80px;
    }
  }
  .ea-disclaimertext {
    color: $color-grey;
    &.evi-product-disclaimer {
      color: $color-dark-grey;
    }
  }

  div.ea-disclaimertext {
    margin-bottom: -3px;
  }

  .evi-product-canceling-confirmation {
    display: flex;
    margin: 0 -10px;
    button {
      flex: 1;
      margin: 0 10px;
    }
  }
  .evi-product-card-price {
    margin: 10px 0;
  }
}

.ea-price-text {
  &.evi-subscriptionprice {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    .ea-price-text__price {
      font-size: 2.5rem;
      margin: 0 10px;
    };
  }
}

.evi-price-text__contract-length {
  color: var(--neutral600-color);
  font-size: 16px;
  margin-bottom: -3px;
  display: inline-block;
}

.evi-price-text__discountprice {
  color: $color-orange;
}

.evi-price-text__terms {
  margin-top: 20px;
  font-size: 20px;
}

.evi-price-text__discountprice-terms {
  color: $color-orange;
  .evi-price-text__terms {
    font-size: 16px;
  }
}

.evi-m-b-10 {
  margin-bottom: 10px;
}

.evi-show-more-button {
  color: $color-linkblue;
  cursor: pointer;
}

.evi-show-more-icon {
  margin-right: 8px;
}

.ea-fgrid--gutter {
  .ea-card.evi-channel-fgrid__item {
    overflow: visible;
    padding-left: 0;

    .usable-platforms {
      margin: 10px 0 5px 0;
    }
  }
}


.evi-channels-list,
.evi-icon-list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 40px 0;
  li {
    margin: 0 8px 8px 0;
    padding: 5px 5px 0 5px;
    position: relative;
    cursor: pointer;
    img,
    .evi-icon--expand-button {
      border-radius: 10px;
      box-shadow: 5px 5px 5px #F5F5F5;
      height: 88px;
      width: 88px;
    }
    &.evi-select-channel-selected {
      border-color: $color-linkblue;
    }
    &.evi-select-channel-disabled {
      border-color: $color-dark-smoke;
      cursor: default;
      > img {
        opacity: 0.4;
      }
    }
    .ea-tooltip--icon {
      position: absolute;
      bottom: 2px;
      right: 2px;
    }

    .evi-mix-channel-price {
      border-radius: 5px;
      position: absolute;
      display: block;
      bottom: 1px;
      right: 1px;
      font-size: 12px;
      line-height: 14px;
      padding: 2px 3px;
      background: $color-light-smoke;
    }
  }
}

.evi-channel-tooltip-modal {
  .ea-modal-box {
    padding: 50px 25px;
  }
}

.evi-channel-tooltip,
.evi-icon-tooltip {
  overflow: auto;
  padding: 0;

  h2 {
    font-size: 2rem;
    margin: 0 0 5px 0;
    display: flex;
    justify-content: center;
    span {
      flex-grow: 1;
      align-self: center;
    }
    img {
      float: right;
      margin-right: 10px;
      height: 88px;
      width: 88px;
    }
  }

  p {
    margin-top: 0;
  }

  &__info {
    background: var(--neutral100-color);
    padding: 10px;
    font-size: 0.9em;

    &:empty {
      display: none;
    }

    li {
      margin-left: 20px;
      margin-bottom: 5px;
      line-height: 1.5;
      list-style-type: circle;

      &:only-child {
        margin-left: 0;
        list-style: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ea-icon--cross {
    margin-top: -2rem;
  }

  &#usable-platforms-tooltip {
    width: auto;
  }
  &.evi-channel-tooltip-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: 80vh;
    width: 90%;
    left: 10px;
    top: 10px;
    right: 10px;
    opacity: .98;
    overflow: auto;
  }
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 5px;
  display:inline-block;
  width: 22px;
  height:22px;
  background: var(--link-color);
  border-radius:50%;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  &--ending {
    background: $color-grey;
  }

  &:before{
    content: "";
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
  }

  &:after{
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
  }
}

.evi-product-header {
  margin: 0 0 16px;
  display: flex;
  justify-content: center;

  span {
    flex-grow: 8;
    align-self: center;
  }

  img {
    height: 80px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin-right: 8px;
    margin-top: 4px;
  }
}

.evi-product-payment-options {
  .evi-product-payment-options-border {
    margin-left: 2.5vw;
    padding-left: 20px;
  }
  .ea-combobox__item:hover {
    background: $color-smoke;
  }
  .ea-notification__link, .ea-notification__link:visited {
    color: #FFF;
  }
}

.evi-table-mixchannels {
  width: 100%;
  tbody {
    border-bottom: 1px solid $color-light-smoke;
  }

  h2 {
    border: none;
    margin: 0;
  }

  td {
    padding: 5px 0;
    vertical-align: bottom;
  }
  td:first-child {padding-right: 50px;}

  td:nth-child(2) {
    text-align: right;
  }
  .evi-table-summary {
    background: $color-white-smoke;
  }
  .evi-table-sum {
    font-weight: bold;
  }
}

.evi-confirm-banner {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  z-index: 100;
  .ea-icon {
    vertical-align: middle;
    margin-right: 10px;
  }
  p {
    margin: 0;
  }
  div {
    margin-top: 10px;
  }
  button {
    margin-left: 20px;
  }
}

.evi-modal-form {
  svg,
  img {
    width: 100px;
  }
  .evi-paymenttype-selection {
    display: inline-block;
    padding: 20px;
    border: 1px solid $color-linkblue;
    border-radius: 5px;
    color: $color-linkblue;
    text-align: center;
    span {
      display: block;
      margin-bottom: 10px;
    }
  }

  .evi-channel-confirm {
    display: flex;
    margin-top: 10px;
    .ea-input__input {
      width: 150px;
      border: 1px solid $color-smoke;
    }
    button {
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: $breakpoint-laptop-min-width) {
  .evi-confirm-banner {
    padding: 10px 20px;
  }
  .evi-channel-tooltip {
    height: 80vh;
    margin-bottom: 60px;
    padding: 0 25px;
    .ea-button--light {
      margin: 20px 0;
      padding: 5px;
      &:before {
        content: '';
      }
    }
  }
  .evi-product-card-need-netpvr {
    margin: -24px -30px 10px -30px;
    padding: 5px 30px;
    .ea-icon--information {margin-left: -25px}
  }
}

@media only screen and (max-width: $breakpoint-tablet-max-width) {
  .evi-payable-content {
    &__empty {
      .ea-context-notification {
        margin-left: 25px;
      }
    }
  }
  .evi-confirm-banner {
    font-size: 1.3rem;
    padding: 10px 20px;
    button {
      margin-top: 5px;
    }
    span.evi-confirm-banner-chargeableChannels {
      display: block;
    }
  }
  .ea-fgrid--gutter {
    .ea-card.evi-channel-fgrid__item {
      padding-left: 0;
    }
  }
  .evi-payable-content__filter {
    .evi-filter--icon {
      font-size: .9rem;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .evi-product-card-need-netpvr {
    margin: -24px -20px 10px -20px;
  }

  .evi-payable-content__filter-top {
    .evi-filter--icon {
      font-size: .9rem;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .evi-product-header {
    line-height: 1em;
  }
  .evi-product-payment-options .evi-product-payment-options-border {
    margin-left: 0;
    padding-left: 0;
    border: none;

  }
  .ea-selectlist-pricelist-narrow > li {
    padding: .6rem 1.5rem;
  }

  .evi-channel-tooltip {
    padding: 10px;
  }
}

.zero-padding {
  padding: 0px;
  margin-bottom: 10px;
}

.title-height {
  margin-bottom: calc(5% + 10px);
}

.body-height {
  margin-top: calc(2% + 1px);
}

