@use "sass:math";
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.evi-watchable-grid-block {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 12px;
    padding: 12px 5% 0;
    position: relative;
  }

  &__title {
    margin: 0;
    padding: 0;
    line-height: 44px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__sort {
    .ea-input {
      width: auto;
    }
  }

  &__items {
    margin: 0 math.div(-$slider-gutter-width, 2);
    padding: 0 5%;

    > .evi-stripe-item {
      margin-bottom: 4vw;

      @include for-desktop-up {
        margin-bottom: 55px;
      }
    }
    .evi-list-element {
      margin: 0;
      
      &__description {
        margin-top: 4px;
        min-height: unset;

        span {
          -webkit-line-clamp: 1;
        }

        &--mobile {
          min-height: unset;
        }
      }

      &__cover-block {
        width: 180px !important;
        .evi-title--coverImage {
          width: 180px !important;
          height: 100px !important;
        }
      }

      &__title>a {
        color: unset;
      }

      .evi-description__expand {
        display: none;
      }

      .evi-play-icon {
        top: 53px;
      }

      .evi-cancel-recording-icon-button {
        display: none;
      }


      @include for-phone-large-down {
        &__description.ea-fgrid {
          margin-top: 4px;
        }
        
        &__cover-block {
          width: 90px !important;
          padding-bottom: 4px;

          .evi-cover-progress {
            height: 2px;
          }
          .evi-title--coverImage {
            width: 90px !important;
            height: 50px !important;
          }
        }

        .evi-play-icon {
          top: 16px;
          left: -6px;
          scale: 0.5;
        }

        &__under-description {
          display: none;
        }

        .evi-description__expand {
          display: unset;
          margin-top: 0px;
        }

        &__small-styling {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }

        &__small-styling-header {
          width: 100%;
          flex-wrap: nowrap;
        }

        &__title {
          margin-top: 0;
          width: 63%;

          button {
            height: 100%;
          }

          >a {
            display: flex;
            width: 100%;
          }

          .mobile {
            flex-direction: column;
            margin-left: 8px;
            margin-top: 8px;

            h5 {
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                margin: 0;
              }

              .evi-list-element__recording-details {
                font-family: "Open Sans",Arial,sans-serif;
                font-style: normal;
                font-weight: 400;
              }
            }
          }

          svg.evi-icon {
            width: 24px;
            height: 24px;
          }  
        }
      }
    }
  }

  &__skeleton {
    > .evi-stripe-skeleton {
      margin-bottom: 4vw;

      @include for-desktop-up {
        margin-bottom: 55px;
      }
    }
  }

  &__empty-message {
    text-align: center;
  }

  &__theme-logo {
    max-width: 50%;
  }
}

.evi-layout-wide .evi-watchable-grid-block {
  @include for-desktop-up {
    &__items > .evi-stripe-item {
      margin-bottom: 4vw;
    }

    &__skeleton > .evi-stripe-skeleton {
      margin-bottom: 4vw;
    }
  }
}
