@import "./slick-carousel/slick";
@import "./slick-carousel/slick-theme";
@import '../../styles/mixins';
@import '../../styles/variables';

@mixin coverImage($width, $height) {
  .ea-video-thumbnail__description {
    max-width: $width;
  }
  .evi-titlecard--image, .evi-titlecard-error {
    width: $width !important;
    height: $height !important;
  }
}

.slick-slider {
  margin-bottom: 0;
}

.slick-track {
  margin-left: 0;
  margin-right: 0;
}

.slick-arrow .ea-icon {
  cursor: pointer;
}

.slick-arrow {
  z-index: 2;
  top: calc(50% - 20px);
  transform: none;
  -webkit-transform: none;
  color: inherit;
  opacity: 0.95;
  font-size: 30px;
  width: 25px;
  height: 25px;
  &:before {
    content: '';
  }
  &:hover {
    opacity: 1;
    color: inherit;
  }
  &:focus {
    opacity: 1;
    color: inherit;
  }
}

.slick-prev {
  left: calc(-5%);
}
.slick-next {
  right: calc(-5%);
}

.slick-list {
  img {
    width: 100%;
  }
  .slick-slide:first-child .evi-titlecard {
    padding-left: 0;
  }
  .evi-titlecard--image {
    max-width: 100%;
  }
  .slick-slide:first-child .evi-eventcard {
    padding-left: 0;
  }

}

.evi-titlecard-error,
.evi-eventcard-error {
  margin: 12px 10px;
  padding: 12px 10px;
  overflow: auto;
  border-radius: $default-border-radius;
}

.evi-titlecard-error {
  width: 160px;
  height: 240px;
}

.evi-eventcard-error {
  width: 320px;
  height: 233px;
}

.ea-video-thumbnail.evi-titlecard {
  cursor: pointer;
}

.ea-button--link-light {
  background: transparent;
  border-color: transparent;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
  }

  @include accessibility-enabled {
    &:active,
    &:focus {
      @include focus-styling-border(3px);
    }
  }
}

.ea-button--float-right {
  float: right;
  right: 0;
  position: absolute;
  top: 5px;
}

.slick-disabled {
  &.slick-arrow, .ea-icon {
    display: none !important;
  }
}

.ea-icon.titleCard {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fcaf17;
}

.ea-icon.eventCard {
  position: absolute;
  top: 8px;
  right: 8px;
}

.evi-stripe--default,
.evi-stripe--giant,
.evi-stripe--landscape,
.evi-stripe--event {
  .ea-video-thumbnail__description {
    padding-top: 0;
    padding-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
  }
  .stripe-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .evi-layout-wide & {
    .stripe-container {
      padding-right: 0;
      padding-left: 0;
    }
    .slick-track {
      margin-left: 5%;
    }
    @media only screen and (min-width: $breakpoint-laptop-min-width) {
      .slick-arrow:not(.slick-disabled) {
        top: 12px;
        padding-bottom: 24px;
        bottom: 12px;
        height: auto;
        width: 40px;
        &.slick-next {
          right: 0px;
        }
        &.slick-prev {
          left: 0px;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    .stripe-container {
      padding-right: 0;
      padding-left: 0;
    }
    .slick-track {
      margin-left: 20px;
    }
    .slick-arrow:not(.slick-disabled) {
      top: 0px;
      padding-bottom: 22px;
      bottom: 0px;
      height: auto;
    }
    @media only screen and (max-width: $breakpoint-phone-large-max-width) {
      .slick-prev {
        left: 0px;
        &:not(.slick-disabled) {
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .slick-next {
      right: 0px;
      &:not(.slick-disabled) {
        background: rgba(255, 255, 255, 0.9);
      }
    }
    .slick-prev {
      left: 0px;
      &:not(.slick-disabled) {
        background: rgba(255, 255, 255, 0.9);
      }
    }
  }
}

.evi-stripe-heading, .evi-grid-heading {
  justify-content: space-between;

  .evi-stripe-heading__title, .evi-grid-heading__title {
    flex: 1;
    flex-grow: 2;
  }

  .evi-stripe-heading__extras, .evi-grid-heading__extras {
    flex-grow: 1;
    overflow: visible;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .ea-input {
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@import 'default.scss';
@import 'giant.scss';
@import 'landscape.scss';
@import 'event.scss';
