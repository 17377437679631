.evi-purchase-wizard-partner-thank-you {
    &__heading {
      padding: 0 0 16px;
    }
  
    &__description {
      margin: 0 0 27px;
    }

    &__activation-prompt {
      .ea-h4 {
        padding: 0;
        margin: 0 0 16px;
      }

      >span {
        display: block;
        margin-bottom: 32px;
      }

      >button {
        margin-bottom: 32px;
      }
    }
  }