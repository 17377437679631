.evi-cover-progress {
    margin: 4px 0 0;
    height: 4px;
    background: rgba(255, 255, 255, 0.08);
    z-index: 2;
    border-radius: 2px;

    // Fix for iOS rendering issue with -webkit-overflow-scrolling: touch;.
    transform: translate3d(0, 0, 0);

    &-bar {
      display: block;
      background: var(--accent600-color);
      height: 100%;
      border-radius: 2px;
    }
    &-bar-live {
      background: var(--status600-color);
    }
}