.evi-input {
  color: var(--neutral700-color);

  &.ea-input .ea-input__label {
    color: var(--neutral700-color);
  }

  &.ea-input--selected .ea-input__label {
    color: var(--link-color);
  }
}
