@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.evi-stripe-slider {
  position: relative;

  &--is-mobile &__button {
    display: none;
  }

  &:not(&--is-touch-device) &__button > b {
    visibility: hidden;
  }

  .evi-stripe-slider:focus-within &__button > b,
  &:hover &__button > b {
    visibility: visible;
  }

  @include accessibility-enabled {
    &__button:focus > b {
      @include focus-styling();
    }
  }

  &--default &__button,
  &--landscape &__button,
  &--giant &__button {
    padding-bottom: 22px;
  }

  .evi-layout-wide & {
    @include for-desktop-up {
      &--default &__button,
      &--landscape &__button,
      &--giant &__button {
        padding-bottom: 1.275vw;
      }
    }
  }

  &--event &__button {
    padding-bottom: 48px;
  }

  &__skeleton {
    visibility: hidden;
    z-index: 1;
  }

  &--is-loading &__skeleton {
    visibility: visible;
  }
}
