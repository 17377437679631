/*
 * Dropdown component CSS based on pattern library
 * (alternative to ea-combobox-* classes)
 */
@import '../../styles/variables';
@import '../../styles/mixins';

.evi-dropdown {
  position: relative;
  width: 100%;
  background: transparent;
  color: var(--neutral700-color);

  .evi-dropdown__input {
    @include accessibility-not-enabled {
      outline: 0;
    }

    .ea-input__wrapper {
      background-color: inherit;
      color: inherit;
    }
    .ea-input__input {
      color: inherit;
    }
  }

  &__modal-header {
    display: none;
  }

  &__input {
    position: relative;

    .ea-input__input {
      cursor: pointer;
    }
  }

  &__options {
    display: none;
    background: var(--neutral100-color);
    max-height: 0;
    overflow: auto;
    list-style: none;
    margin: 0;
    padding: 7px 0;
    white-space: nowrap;
    z-index: $zindex-dropdown-normal;
    position: absolute;
    top: calc(100% + #{$global-border-thick});
    left: -$global-border-thick;
    width: calc(100% + #{$global-border-thick});
  }

  &__item {
      display: table-row;
      line-height: 2.4rem;
      padding: ($global-baseline - $global-border-thick) ($global-column-cap - $global-border-thick);
      line-height: $global-baseline * 2;
      font-size: 16px;
      font-weight: normal;
      cursor: pointer;

      a, a:visited, span {
        color: var(--neutral700-color);
        padding: 5px 12px;
        display: block;
      }

      div, &--selected span {
        color: var(--neutral700-color);
        font-weight: bold;
        padding: 7px 12px;
      }

      &:not(&--selected):hover {
        a, span {
          color: var(--link-color);
         background-color: $color-hover;
        }
      }
    }

  &--open .evi-dropdown__options {
    display: table;
  }

  &__overlay {
    display: none;
  }

  // Full screen modal
  @media only screen and (max-width: $breakpoint-phone-max-width) {
    $header-height: (2 * $global-baseline + 24px);

    &__modal-header {
      margin-top: $global-baseline;
      color: inherit;
      font-size: 18px;
      line-height: 1;
      text-align: right;
    }

    &__close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      color: var(--neutral700-color);
    }

    &__options {
      position: relative;
      font-weight: normal;
      user-select: none;
      z-index: $zindex-overlay;
    }

    &__item {
      font-size: 18px;
    }

    &--open {
      position: fixed;
      top: $fullscreen-top-offset;
      bottom: 0;
      right: 0;
      width: 100%;
      background: var(--neutral100-color);
      overflow-y: scroll;
      z-index: $zindex-dropdown-fullscreen;
      padding: $global-baseline $global-column-cap;
    }

    &--open .evi-dropdown__modal-header {
      display: block;
      margin-bottom: $global-baseline;
      min-height: $global-baseline * 2;
    }

    &--open .evi-dropdown__options {
      top: 0;
      max-height: calc(100vh - #{$global-baseline} * 5);
      overflow-y: auto;
      overflow-x: hidden;
    }

    &--open .evi-dropdown__input {
      display: none;
      &:after {
        display: none;
      }
    }

    &--open#{&}--show-input {
      .evi-dropdown__options {
        max-height: calc(100vh - #{$header-height * 2});
      }
      .evi-dropdown__input {
        display: block;
      }
    }
  }

  // Dropdown
  @media only screen and (min-width: $breakpoint-phone-large-min-width) {
    &__options {
      -webkit-transition: 0.25s cubic-bezier(0.82, 0, 0.1, 1);
      transition: 0.25s cubic-bezier(0.82, 0, 0.1, 1);
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: top;
      transform-origin: top;
    }

    &--open .evi-dropdown__options {
      max-height: none;
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
      border-color: $color-dark-smoke;

      overflow-y: auto;
      overflow-x: visible;

      z-index: $zindex-dropdown-normal;

      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    }

    &--open .evi-dropdown__overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--dropdown .evi-dropdown__input {
    position: relative;

    &::after {
      position: absolute;
      right: 12px;
      top: 50%;
      transition: transform 0.3s ease-in-out;
      transform: translateY(-50%) scaleY(1);

      color: inherit;
      font-size: 20px;
      font-family: $global-font-icon;
      font-weight: 400;
      content: $elisaicon-arrow-down;
      cursor: pointer;
    }
    .ea-input__input {
      padding-right: 28px + $global-baseline;
    }
  }

  &--open#{&}--dropdown .evi-dropdown__input::after {
    transform: translateY(-50%) scaleY(-1);
  }
}
