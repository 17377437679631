@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-code-input {
  margin: 2em 0;

  &__items {
    display: flex;
    align-items: center;
    margin: 0 -5px;

    > i {
      margin: 0 15px;
      cursor: pointer;
    }
  }

  &__item {
    display: inline-block;
    margin: 0 5px;
  }

  input {
    width: 60px;
    color: $color-dark-grey;
    caret-color: transparent;
    font-size: 40px;
    text-align: center;
    padding: 0;
    border: solid 3px var(--link-color);
    border-radius: 5%;
    cursor: pointer;

    &:not(:focus) {
      color: $color-dark-grey !important;
      background-color: $color-white !important;
      border-color: #e0e0e1 !important;
    }

    &:focus {
      border-color: $color-linkblue;
      outline: none;
    }
  }

  &__code-visibility-button {
    border: none;
    background: transparent;
  }

  &--error {
    input {
      border-color: $color-error;
    }
  }

  &__error-message {
    color: $color-error;
    margin: .5em 0 0;
    white-space: pre-wrap;
  }

  @media (max-width: $breakpoint-phone-max-width) {
    &__items {
      margin: 0 -2px;

      > i {
        margin: 0 12px;
      }
    }

    &__item {
      margin: 0 2px;
    }

    input {
      width: 50px;
      font-size: 30px;
    }
  }

  @media (max-width: 369px) {
    input {
      width: 40px;
      font-size: 25px;
    }
  }
}
