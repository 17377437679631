@import '../../../styles/mixins.scss';

.evi-purchase-wizard-identification {
  &__providers {
    padding: 20px 0 35px;
  }

  &__billing-address {
    padding: 0 0 20px;
  }
}
