@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.evi-event-card {
  > a {
    display: block;
    color: inherit;
  }

  &__cover {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    background: var(--neutral100-color);
    border-radius: $default-border-radius $default-border-radius 0 0;
    padding: $watchable-card-height-event 0 0 0;
    z-index: 1;

    &-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        will-change: opacity;
        transition: opacity 0.3s ease;
      }
    }
  }

  &__progress {
    position: absolute;
    height: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(235, 235, 235, 0.4);
    z-index: 2;

    // Fix for iOS rendering issue with -webkit-overflow-scrolling: touch;.
    transform: translate3d(0, 0, 0);

    &-bar {
      display: block;
      background: $color-linkblue;
      height: 100%;
      border-bottom-left-radius: 2px;
    }
  }

  &__status {
    display: flex;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding: 0 10px;
    justify-content: space-between;
    border-radius: 0 0 $default-border-radius $default-border-radius;
    text-transform: uppercase;

    &--starting {
      color: $event-card-status-color-starting;
      background: $event-card-status-background-starting;
    }

    &--live {
      color: $event-card-status-color-live;
      background: $event-card-status-background-live;
    }

    &--archived {
      color: $event-card-status-color-archived;
      background: var(--neutral400-color);
    }

    &--upComing {
      color: $event-card-status-color-up-coming;
      background: var(--neutral700-color);
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    color: var(--neutral700-color);
    padding: 0 10px;
    margin-top: 5px;
  }

  &__title-text {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__quality {
    display: flex;
    margin-left: 5px;
  }

  &__series {
    padding: 0 10px;
    color: var(--neutral700-color);
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--is-loading &__cover-image img {
    opacity: 0;
  }

  &--is-loaded &__cover-image img {
    opacity: 1;
  }

  &--has-error &__cover-image img {
    display: none;
  }
}
