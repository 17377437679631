@import '../../../styles/_variables.scss';

.evi-userinfo {
  .ea-icon {
    margin-right: 10px;
    float: left;
  }
}

.evi-offline-device-list-item {
  display: flex;
  margin-bottom: 20px;
  .ea-icon {
    margin-right: 15px;
    &.ea-icon--cross {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
