@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-comparison-table-block {
  display: block;
  width: 90%;
  padding: 30px 0 50px;
  margin: 0 auto;
  overflow: hidden;

  &__title {
    text-align: center;
    margin-bottom: 30px;
  }
}

// FLEX-TABLE
.evi-comparison-table {
  &__wrapper {
    --selected-product: 1;
    --product-count: 3;
    --line-height: 1.5;
    --label-font-size: 16px;
    --highlight-font-size: 16px;
    --cell-padding: 24px;
    --cell-spacing: 16px;
    --label-width: 320px;
    --product-background-color: rgba(242, 245, 251, .05);
    --row-border-color: #384056;
    --row-background-color: #262d43;
    --alternative-color: #c9d3e5;

    @include for-laptop-down {
      --line-height: 1.6;
      --label-width: 250px;
      --label-font-size: 14px;
      --highlight-font-size: 15px;
    }

    @include for-tablet-only {
      --label-font-size: 13px;
      --cell-padding: 16px;
      --cell-spacing: 10px;
    }

    @include for-phone-large-down {
      --label-font-size: 13px;
      --cell-padding: 12px;
      --cell-spacing: 0px;
    }
  }

  width: 100%;
  position: relative;
  font-size: 13px;
  line-height: var(--line-height);

  &__navigation {
    display: none;
    visibility: hidden;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    transition: 0.5s;
    border-bottom: 1px solid var(--row-border-color);
    background: var(--row-background-color);

    @include for-tablet-up {
      &:nth-child(odd) {
        background: transparent;
      }
    }
  }

  &__row:first-child &__cell {
    padding: 0;
    position: relative;

    &.has-ribbon {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - (var(--label-width) - var(--cell-spacing))) / var(--product-count) - (1 - 1/var(--product-count)) * var(--cell-spacing));
    padding: var(--cell-padding);
    background: var(--product-background-color);
    text-align: center;

    @include for-laptop-up {
      padding: calc(var(--cell-padding) * (5/6)) calc(var(--cell-padding) * 1.5);
    }

    &:not(.first) {
      flex-direction: column;
      line-height: 1.23;
    }

    &.first {
      justify-content: left;
      width: calc(var(--label-width) - var(--cell-spacing));
      font-size: var(--label-font-size);
      text-align: left;
      color: var(--alternative-color);
      padding: var(--cell-padding) calc(var(--cell-padding) - var(--cell-spacing)) var(--cell-padding) var(--cell-padding);

      @include for-tablet-up {
        background: none;
      }
    }

    &.highlighted {
      font-size: var(--highlight-font-size);
      font-style: italic;
      justify-content: start;
      text-align: left;
      line-height: var(--line-height);
    }

    &:not(.highlighted) span {
      max-width: 288px;
    }
  }

  i.ea-icon {
    &--check {
      color: #00a950;
      position: relative;
      top: -3px;
    }

    &--cross {
      font-size: 21px;
      color: #e44;
      margin-bottom: 4px;
    }

    &--euro {
      clip-path: circle(30% at 50% 50%);
      font-size: 29px;
      color: var(--alternative-color);
    }
  }

  @include for-tablet-only {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: linear-gradient(to right,
        var(--product-background-color) 0,
        var(--product-background-color) calc(100% / var(--product-count) - (var(--cell-spacing) / 2)),
        transparent calc(100% / var(--product-count) - (var(--cell-spacing) / 2)),
        transparent calc(100% / var(--product-count) + (var(--cell-spacing) / 2)),
        var(--product-background-color) calc(100% / var(--product-count) + (var(--cell-spacing) / 2)),
        var(--product-background-color) calc(100% / var(--product-count) * 2 - (var(--cell-spacing) / 2)),
        transparent calc(100% / var(--product-count) * 2 - (var(--cell-spacing) / 2)),
        transparent calc(100% / var(--product-count) * 2 + (var(--cell-spacing) / 2)),
        var(--product-background-color) calc(100% / var(--product-count) * 2 + (var(--cell-spacing) / 2)),
        var(--product-background-color) 100%
      );
    }

    &__row {
      &:nth-child(2n+1) {
        background: transparent;
      }
    }

    &__cell {
      $item-width: calc(100% / var(--product-count) - var(--cell-spacing) / 2);
      width: $item-width;
      background: transparent;
      
      &.first {
        flex: 0 0 100%;
        width: $item-width;
        padding-right: calc(100% - #{$item-width} + var(--cell-padding));
        padding-bottom: 0;
        line-height: 1.23;

        &:empty {
          display: none;
        }
      }
    }
  }

  @include for-phone-large-down {
    $slide-transition: 0.5s ease-in-out;
    width: calc(var(--product-count) * 100%);
    transform: translateX(calc(-100% / var(--product-count) * var(--selected-product)));
    transition: transform $slide-transition;
    touch-action: pan-y pinch-zoom;

    &__navigation {
      display: block;
      visibility: visible;
      margin-bottom: 16px;

      &-container {
        background-color: var(--neutral-color);
        padding-bottom: 10px;
        transition: transform .3s ease-out;
      }

      &-sticky &-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 10px 5%;

        header.ea-header:not(.ea-header--hidden)~#viihdeonline-react-app-content & {
          -webkit-transform: translateY(61px);
          transform: translateY(61px);
        }
      }
    }

    &__navigation-content {
      display: flex;
      margin: 0 auto;
      border: 2px solid var(--alternative-color);
      border-radius: 2px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: calc(100% / var(--product-count));
        height: 100%;
        background: var(--alternative-color);
        position: absolute;
        left: calc(100% / var(--product-count) * var(--selected-product));
        top: 0;
        pointer-events: none;
        transition: left 0.3s ease;
      }

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: calc(100% / var(--product-count) * var(--selected-product) + 100% / var(--product-count) / 2);
        top: 100%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 8px solid var(--alternative-color);
        transition: left 0.3s ease;
      }

      span {
        display: flex;
        width: calc(100% / var(--product-count));
        justify-content: center;
        align-items: center;
        padding: 6px 3%;
        font-size: 10px;
        font-weight: 600;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        word-break: break-word;
        z-index: 0;

        &.selected {
          color: var(--neutral-color);
          transition: color .3s ease;
        }
      }
    }

    &__row {
      &:nth-child(2n+1) {
        background: transparent;
      }
    }

    &__row:not(:first-child) &__cell {
      background: transparent;
    }

    &__cell {
      $item-width: calc(100% / var(--product-count) - var(--cell-spacing) / 2);
      width: $item-width;
      
      &.first {
        flex: 0 0 100%;
        width: $item-width;
        padding-right: calc(100% - #{$item-width} + var(--cell-padding));
        padding-bottom: 5px;
        line-height: 1.23;
        transform: translateX(calc(100% / var(--product-count) * var(--selected-product)));
        transition: transform $slide-transition;

        &:empty {
          display: none;
        }
      }
    }
  }

  @media (max-width: 350px) {
    &__navigation-content {
      flex-direction: column;

      &:before {
        content: '';
        width: 100%;
        height: calc(100% / var(--product-count));
        left: 0;
        top: calc(100% / var(--product-count) * var(--selected-product));
        transition: top 0.3s ease;
      }

      &:after {
        display: none;
      }

      span {
        width: 100%;
        min-height: 40px;
      }
    }
  }
}
