@import '../../../../styles/variables.scss';

.evi-purchase-wizard-channel-package-thank-you {
  &__heading {
    padding: 0 0 16px;
  }

  &__description {
    margin: 0;
    padding: 0 0 30px;
    border-bottom: 1px solid $color-dark-smoke;
  }

  &__actions {
    margin: 45px 0 0 0;
    text-align: right;
  }

  &__close-button {
    margin: 0;
  }
}
