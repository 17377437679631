@import '../../../styles/_variables.scss';

.evi-trailer-block {
  h1 a {
    color: inherit;
    text-transform: uppercase;
  }
  
  .evi-player-controls-back {
    display: none;
  }

  .evi-play-next {
    position: absolute;
    right: $player-controls-margin-desktop;
    bottom: 80px;
    z-index: 2147483647;

    button {
      margin: 0 10px;
    }

    @media screen and (max-width: $breakpoint-laptop-max-width) {
      right: $player-controls-margin-mobile;
    }
  }

  @media screen and (max-width: $breakpoint-phone-large-max-width) {
    &--play-next-visible #videoContainer #videoPlaceholder {
      margin-bottom: 110px;
      overflow: visible;

      .evi-play-next {
        bottom: -110px;
        right: auto;
        width: 100%;
      }
    }
  }

  .evi-title-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 30px 0;

    .evi-purchase-buttons {
      margin: 0;
    }

    .evi-title-play-icon {
      float: left;
      font-size: 24px;
      margin-right: 10px;
    }

    .evi-title-actions__primary-action {
      margin: 0;
    }

    .evi-title-actions__secondary-action {
      display: flex;
      flex-direction: row;
      margin: 0;

      button, a {
        padding: 0;
        margin: 0 0 0 20px;

        span {
          margin: 0;
        }
      }

      svg,
      .ea-icon {
        margin: 0 7px 0 0;
      }
    }
  }

  &__toggle-autoplay-next {
    margin: 0 0 0 20px;
  }

  &__header {
    margin: 0 0 30px;
  }

  &__genres,
  &__cast,
  &__filters {
    margin: 0 0 20px;
  }

  &__cast {
    a {
      color: var(--link-color);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
