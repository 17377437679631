.frontpage-layout {
  .main-title {
    h2 {
      width: 710px;
      text-align: center;
    }
  }

  .evi-icon-list {
      &.alternative-layout {
        gap: 16px;
        justify-content: flex-start;

        li {
          max-width: 210px;
          height: 100px;
          width: 100%;
          margin: 0;
          position: unset;
          justify-content: center;
          display: flex;
          background-color: white;
          padding: 20px 0 20px 0;
          border-radius: 2px;
          
          img {
            width: 88px;
            height: 88px;
            box-shadow: none;
            border-radius: 0;
            align-self: center;
          }
        }
      }
    }
}

