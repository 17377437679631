@import '../../styles/variables.scss';

.evi-partner-account-activation-polling {
  &__heading {
    padding: 0 0 16px;
  }

  &__description {
    margin: 0 0 27px;
  }

  &__loading-indicator {
    display: flex;
    align-items: center;

    &-label {
      flex: 1;
      color: $color-grey;
    }
  }
}
