@import '../../../../styles/variables.scss';

.evi-crm-price-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 24px 0;

  &--has-discount &__price {
    color: $color-orange;
  }

  &__price-title {
    display: flex;
    width: 100%;

    &-price {
      flex: 1;
    }
  }

  &__price {
    font-weight: normal;
  }

  &__terms {
    flex: 1;
    padding: 0 35px 0 0;
    font-size: 13px;
    line-height: 18px;
    color: #70707A;
  }

  &__price-description {
    font-size: 13px;
    line-height: 20px;
    color: #70707A;
    text-align: right;

    > span {
      display: block;
    }
  }

}
