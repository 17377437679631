@import '../../../styles/mixins';
@import '../../../styles/variables';

.evi-toggle {
    display: flex;

    &__toggle-option {
        padding: 6px 8px;
        border-radius: 2px;
        display: flex;
        transition: all 0.3s ease;

        svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral600-color);
        }

        span {
            font-size: 16px;
            font-family: "Jost", sans-serif;
            text-transform: uppercase;
            font-weight: normal;
            margin-left: 8px;
            display: inline-block;
        }
    }

    &__toggle-option-selected {
        background-color: var(--accent400-color);
        color: var(--neutral700-color);
        
        svg {
            fill: var(--neutral700-color);
        }
    }
    &__toggle-option-not-selected:hover {
        background-color: rgba(150, 197, 255, 0.5);;
    }

    :first-child {
        margin-right: 2px;
    }

    @include for-phone-large-down {
        margin-left: 8px;

        &__toggle-option span {
            display: none;
        }
    }
}
