@import '../../../styles/mixins.scss';

.evi-purchase-wizard-product-configuration-step {
  padding: 0 50px;

  @include for-phone-large-down {
    padding: 0 25px;
  }

  &__product-information {
    padding: 0 0 43px;
  }

  &__errors {
    padding: 0 0 30px;
  }
}
