.evi-stb-sign-up {
  width: 100%;
  max-width: 500px;

  &__button-description {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin: 16px 0 13px;
  }
}
