@import '../../../styles/variables.scss';

.evi-popular-recordings {
  background-color: var(--neutral100-color);
  color: var(--neutral700-color);

  .evi-stripe-heading__title {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .ea-loading {
    display: inline-block;
    margin: 0 0 0 20px;
    position: absolute;
  }
  p.ea-center-block {
    width: 100%;
  }
  .evi-record-title-link {
    text-decoration: unset;
  }
  .evi-record-title-link:hover {
    color: inherit;
  }
}

.evi-popular-recordings-wrapper {
  padding-right: 20px;
}
.evi-popular-recordings-item {
  border-color: var(--neutral400-color);

  .ea-fgrid__item {
    padding-top: 20px;
  }
}

.evi-popular-recordings-item {
  align-content: flex-start;
  .ea-center-block, img {width: 100%;}
}

.evi-popular-recordings-item {
  border-bottom: 1px solid #999;
  padding-bottom: 20px;
  a {
    color: var(--neutral700-color);
  }
  h3.ea-ingresstext {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  p.evi-timeschedule {
    padding-top: 0;
    margin-top: 0;
    font-size: 14px;
    text-transform: capitalize;
  }
  p.evi-shortdescription {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    max-height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.evi-record {
  cursor: pointer;
  position: relative;
  display: block;
  min-height: auto;

  .evi-record-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.4);
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
    img {
      margin: 0;
      padding: 0;
    }
    .evi-record-icon-wrapper {
      display: inline-block;
      border-radius: 23px;
      margin-right: 10px;
      text-align: center;
      font-size: 2em;
      background: rgba(0, 0, 0, 0.5);
      .evi-record-icon {
        display: inline-block;
        border: 2px solid white;
        width: 14px;
        height: 14px;
        margin: 15px;
        padding: 5px;
        border-radius: 7px;
      }
      .evi-record-icon--scheduled {
        background: #ee4444;
        border-color: #ee4444;
      }
    }
  }
}

.evi-filter-group {
  width: 40%;
  max-width: 700px;
  
  label {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  label:hover {
    background-color: var(--neutral300-color);;
  }

  .ds-selectgroup__list-item--selected {
    background-color: var(--button-color);
    .ds-selectgroup__list-item__text {
      color: var(--neutral700-color);
      font-weight: 600;
    }
  }
  .ds-selectgroup__list-item--selected:hover {
    background-color: var(--button-color);
  }
}

.evi-popular-recordings-filter {
  display: inline-block;
  a {
    display: inline-block;
    margin: 0 10px;
    font-size: .8em;
    color: var(--link-color);
    
    &.active {
      font-weight: bold;
    }
  }
}

@media screen and (min-width: $breakpoint-tablet-min-width) {
  .evi-popular-recordings-item {
    margin: 0 5% 20px calc(5% - 20px);
    .ea-fgrid__item {
      &:first-child {
        padding-right: 20px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
  .evi-record-icon-label {
    display: none;
  }
  .evi-record-icon-wrapper {
    margin-right: 0;
  }
}

@media screen and (max-width: $breakpoint-tablet-max-width) {
  .evi-popular-recordings-item h3.ea-ingresstext {
    font-size: initial;
  }

  .evi-filter-group {
    width: 100%;
  }

  .evi-stripe-heading__title {
    flex-direction: column;
  }

  .evi-shortdescription {
    display: none;
  }
}

@media screen and (min-width: $breakpoint-laptop-min-width) and (max-width: $breakpoint-laptop-max-width) {
  .evi-filter-group {
    width: 56%;
  }
}
