@import "~@design-system/component-library/src/globals/scss/_variables.scss";

.device-list-block {
  margin: 112px 0;

  h2 {
    width: 560px;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
  }

  .short-text p {
    font-size: 16px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 32px 0 112px 0;
  }

  .devices-container {
    gap:120px;

    .device-block {
      width: 105px;
      height: 160px;
      flex-direction: column;

      .ds-icon {
        display: flex;
        align-self: center;

        svg {
          fill: var(--neutral700-color);
        }

        &.rotate-90 {
          transform: rotate(90deg);
        }

        &.rotate-180 {
          transform: rotate(180deg);
        }

        &.rotate-270 {
          transform: rotate(-90deg);
        }
      }

      .device-block-text {
        margin-top: 16px;

        p {
          margin-block-start: 0;
          margin-block-end: 0;
          font-size: 14px;
          text-align: center;
          font-weight: 700;
          line-height: 22px;
        }
      }
      
      .ds-icon--size-xl {
        width: 80px;
        height: 80px;
      }
    }
  }
}

@media (max-width: $breakpoint-l) {
  .device-list-block {
    .devices-container {
      gap: 50px;

      .device-block {
        width: 35%;
      }
    }
  }
}
