@import '../../../styles/variables';

.evi-smart-login {
  &--form {
    padding-bottom: 10px;
  }

  &--success {
    text-align: center;

    .ea-icon {
      display: inline-block;
      border: solid 2px;
      border-color: $color-green;
      color: $color-green;
      padding: 7px;
      margin: 0 0 15px;
      border-radius: 50%;
    }
  }

  .ea-button:not(:focus) {
    border-color: transparent !important;
  }

  .evi-code-input__items {
    justify-content: center;
  }
}