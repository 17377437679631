@import '../styles/_variables.scss';

@keyframes focusZoom {
  0% {
    transform: scale(1);
    outline: $theme-default-accent600-color 2px solid;
  }

  50% {
    transform: scale(1.05);
    outline: $theme-default-accent600-color 4px solid;
  }

  100% {
    transform: scale(1);
    outline: $theme-default-accent600-color 2px solid;
  }
}

.evi-episodes {
  padding-top: 8px;
      
  .evi-purchase-cover-wrapper {
    margin: 0;
  }
}

.evi-title--noCoverImage {
  background: #e0e0e1;
  padding-top: 125%;
}

.evi-season-selector {
  display: flex;
  align-items: center;

  .evi-season-selector-label,
  .evi-season-selector-link {
    margin-right: 12px;
  }

  .evi-season-selector-link {
    &, &:link, &:visited {
      &:not(.ea-button--lightblue) {
        color: inherit;
      }
    }
    padding-left: 16px;
    padding-right: 16px;
  }
}

.ea-episode {
  img {
    max-width: 100%;
  }
  padding-bottom: 12px;
}

.ea-episode .evi-title-cover-block,
.evi-list-element__cover-block {
  position: relative;
  display: block;
  margin-right: 24px;
  width: 140px;
  max-width: 100%;
  border-radius: $default-border-radius;
  img {
    display: block;
  }
  &--landscape {
    width: 50%;
    max-width: 320px;
    padding-bottom: 8px;
  }
}

.evi-list-element {
  &__cover-block {
    margin-bottom: auto;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    color: var(--neutral600-color);
  
    h5 {
      margin: 0;
    }

    .mobile {
      display: flex;
      flex-wrap: nowrap;

      .runtime {
        flex-grow: 1;

        text-align: end;

        span {
          margin-left: 10px;
          white-space: nowrap;
        }
      }
    }
  }

  &__description--mobile {

    flex-wrap: nowrap;

    button, a {
      align-self: flex-start;
      margin-top: 8px;
      margin-left: 10px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .evi-list-element__description {
      width: 100%;
    }

    .evi-button--container {
      &__full {
        width: 100%;
      }

      button, a {
        float: right;
      }
    }
  }

  &__under-description {
    display: flex;
    align-items: center;

    svg.ea-ratings {
      height: 20px;
      width: 20px;
      margin: 0 4px 0 0;
    }

    .evi-purchase {
      margin-right: 8px;
    }


    button {
      white-space: nowrap;
    }

    button, a, a:visited, a:active {
      color: var(--accent800-color);
      border-color: var(--accent800-color);
      opacity: 0.85;
      margin-right: 20px;

      svg.evi-icon {
        position: relative;
        top: 1px;
        height: 17px;
        width: 17px;
        line-height: 17px;
        font-size: 17px;
      }

      span:not(.ea-icon, .ds-button__content) {
        vertical-align: 10%;
        margin-left: 8px;
      }

      &:hover {
        color: var(--accent800-color);
        border-color: var(--accent800-color);
        opacity: 1;
      }

      span, span:hover {
        color: var(--accent800-color);
      }

      & span.ea-icon {
        position: relative;
        top: 2px;
        line-height: 17px;
        font-size: 17px;
      }
    }
  }

  &__recording-details {
    font-size: 14px;
    color: var(--neutral600-color);
    margin-right: 8px;
  }

  &:focus-within {
    .evi-title--coverImage {
      outline: var(--accent600-color) 2px solid;
    }

    .evi-title-play-link {
      outline: none;
      outline-offset: unset;
    }
  }

  .evi-episodes__initial-focus &:focus-within {
    .evi-title--coverImage {
      animation-name: focusZoom;
      animation-delay: 700ms;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
    }
  }
}

.evi-title-play-link {
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 8px;
  left: 0;
  color: white;
  transition: outline ease-in-out 300ms;

  .evi-play-icon {
    position: absolute;
    top: 130px;
    left: 8px;
    color: white;
    z-index: 2;
    width: 40px;
    height: 40px;
  
    circle {
      transition: fill 0.3s ease-out;
      transition: opacity 0.3s ease-out;
    }
  }

  &:hover {
    svg.evi-play-icon {
      circle {
        fill: var(--accent400-color);
        opacity: 1;
      }
    }
  }

  &:active {
    svg.evi-play-icon {
      circle {
        fill: var(--accent800-color);
      }
    }
  }
}

.evi-title--coverImage {
  .evi-cancel-recording-icon-button {
    background: none;
    border: none !important;
    left: 52px;
    padding-top: 0 !important;
    position: absolute !important;
    top: 130px;
    z-index: 2;

    svg {
      circle {
        transition: fill 0.3s ease-out;
        transition: opacity 0.3s ease-out;
      }
    }

    &:hover {
      svg {
        circle#circle131, circle#circle407 {
          fill: var(--accent400-color);
          opacity: 1;
        }
      }
    }
  }
}

.evi-cover__progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.evi-title-details-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 8px;
  left: 0;
}

@media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
  .evi-list-element-cover-block .evi-title-play-icon {
    font-size: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }

  .evi-list-element__under-description {
    flex-direction: column-reverse;
    align-items: start;

    button {
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: $breakpoint-phone-large-max-width) {
  .ea-episode .evi-ratings {
    text-align: left;
  }

  .evi-list-element__cover-block--landscape {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  .evi-episodes {
    margin-right: 20px;
  }

  .evi-list-element__title {
    margin-top: 8px;
  }

  .evi-list-element__description-container>span {
    -webkit-line-clamp: 2;
  }

  .evi-title-play-link {
    .evi-play-icon {
      top: 130px;
    }
  }

  .evi-title--coverImage {
    .evi-cancel-recording-icon-button {
      top: 150px;
    }
  }
}

@media screen and (min-width: $breakpoint-laptop-min-width) {
  .evi-list-element-expand:hover {
    color: var(--accent800-color);
  }
}

