.evi-accordion {
  &__header {
    line-height: initial;
    position: relative;
    margin-right: 0;

    button {
      background: transparent;
      border: none;
      width: calc(100% + 12px);
      display: flex;
      padding: 0 6px;
      margin: 0 -6px;
      z-index: 1;
    }
  }

  .ea-accordion__header::before {
    top: 50%;
    margin-top: -12px;
    line-height: initial;
  }

  &:not(&--expanded) &__content {
    visibility: hidden;
  }
}
