@import '../../../styles/variables';

.evi-purchase-wizard-actions {
  border-top: 1px solid $color-dark-smoke;

  &:not(&--has-description) {
    padding: 48px 0 0;
  }

  &__actions {
    margin: 0 -4px;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    margin: 0 4px;
  }

  &__description {
    padding: 20px 0;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #40404A;
  }

  .ea-button--lightblue .evi-spinner {
    border-color: #fff #fff transparent #fff !important;
  }
}
