@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-viewmenu {
  color: var(--neutral700-color);
  padding: 6px 0;
  border-bottom: 1px solid var(--neutral400-color);

  .ea-input__wrapper {
    background-color: inherit;
    color: inherit;
    border: none;

    &:focus-within {
      @include focus-styling();

      @include accessibility-not-enabled {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
      }
    }

    .ea-input__input {
      color: inherit;

      @include accessibility-not-enabled {
        a {
          outline: 0;
        }
      }
    }
  }

  @media only screen and (min-width: $breakpoint-laptop-min-width) {
    .ea-input__input {
      font-weight: 600;
    }
  }

  .evi-viewmenu-container__item {
    display: flex;
    align-items: center;
    min-height: 44px;
    a, a:link {
      color: inherit;
    }
  }

  .evi-viewmenu-to-movie-list {
    .ea-icon {
      vertical-align: middle;
    }
  }

  .evi-viewmenu__dropdown {
    width: auto;
  }

  .evi-viewmenu-first {
    .ea-input__input {
      padding-left: 0;
    }
  }
}
