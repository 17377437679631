.evi-stb-devices {
  &__help-text {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
  .help-text-small-margin {
    margin: 2px 0 0 0;
  }
  &__device-details {
    display: flex;
    flex-direction: column;
  }
  &__device-description {
    flex-grow: 1;
    margin: 12px 0;
  }
  &__device-details-button {
    margin-bottom: 12px;
  }
}
