@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.evi-toast-notifications {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  z-index: 20;

  @include for-laptop-up {
    top: $header-height-large;
  }

  .evi-toast-notification {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }
}
