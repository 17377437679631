.evi-stripe--landscape, .evi-grid--landscape {
  $default-item-width: 280px;
  $default-item-height: 157px;
  $tablet-item-width: 200px;
  $tablet-item-height: 112px;
  $phone-item-width: 160px;
  $phone-item-height: 90px;

  .evi-infinite-list {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, $default-item-width);
    row-gap: 20px;
    column-gap: 6px;
    justify-content: space-evenly;
    .evi-titlecard {
      padding: 0;
    }
  }
  .slick-list {
    .evi-titlecard {
      padding-left: 5px;
      padding-right: 5px;
      max-width: 100%;
    }
  }
  @include coverImage($default-item-width, $default-item-height);

  @media only screen and (min-width: 1280px) {
    .evi-infinite-list {
      justify-content: space-between;
    }
  }

  @media only screen and (min-width: $breakpoint-tablet-min-width) and (max-width: $breakpoint-tablet-max-width) {
    .slick-list, .evi-infinite-list {
      @include coverImage($tablet-item-width, $tablet-item-height);
    }
    .slick-list {
      .evi-titlecard {
        padding: 5px 6px 5px 0;
      }
    }
    .evi-infinite-list {
      grid-template-columns: repeat(auto-fill, $tablet-item-width);
      .evi-titlecard {
        row-gap: 6px;
        column-gap: 6px;
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: $breakpoint-phone-large-max-width) {
    .slick-list, .evi-infinite-list {
      @include coverImage($phone-item-width, $phone-item-height);
    }
    .evi-infinite-list {
      margin-left: 0;
      margin-right: 0;
      row-gap: 6px;
      column-gap: 0px;
      grid-template-columns: repeat(auto-fill, $phone-item-width);
      .evi-titlecard {
        padding: 0;
      }
    }
    .slick-list {
      .evi-titlecard {
        padding: 5px 6px 5px 0;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet-max-width) {
    .slick-list, .evi-infinite-list {
      .ea-video-thumbnail__description {
        font-size: 14px;
      }
    }
  }
}