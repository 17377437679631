.evi-rent-and-buy-purchase-options {
  &__title {
    padding: 0 0 16px;
  }

  &__description {
    margin: 0;
    padding: 0 0 24px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    max-width: 310px;

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      > * {
        width: 100%;
      }
    }
  }
}
