@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.evi-watchable-card {
  &--alternative-styling {
    background-color: var(--neutral200-color);
  }

  > a {
    display: block;
    color: inherit;
    border-radius: $default-border-radius;
    overflow: hidden;
  }

  &--has-play-link a {
    &:hover {
      svg.evi-watchable-card__play-link {
        circle {
          fill: #006AED; // should add as var, add to theme accent 400
          opacity: 1;
        }
      }
    }

    &:active {
      svg.evi-watchable-card__play-link {
        circle {
          fill: var(--accent800-color);
        }
      }
    }
  }

  &__cover {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    background-color: var(--neutral100-color);
    z-index: 1;

    &-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        will-change: opacity;
        transition: opacity 0.3s ease;
      }
    }
  }

  &__info {
    margin-bottom: 8px;
  }

  &--has-play-link &__info {
    padding-right: 30px;
    position: relative;

    .ea-icon {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  &__title, &__episode-title {
    font-style: normal;
    font-weight: 400;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 8px 0 0;

    @include for-phone-large-down {
      font-size: 14px;
    }
  }

  &__title {
    color: var(--accent800-color) !important;
  }

  &--alternative-styling &__title,
  &--alternative-styling &__episode-title {
    margin: 4px 8px 0 8px;
  }

  &__episode-title {
    color: var(--neutral600-color) !important;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    margin-top: 0;
  }

  &__channel-logo {
    position: absolute;
    top: 0;
    height: 32%;
    width: 100%;
    z-index: 2;
    background: linear-gradient(180deg, rgba(16, 18, 33, 0.5) 0%, rgba(16, 18, 33, 0) 100%);

    > img {
      height: 50%;
      position: absolute;
      top: 13.25%;
      right: 3.18%;
    }
  }

  &__favorite {
    position: absolute;
    top: 3.5%;
    left: 5.26%;
    width: 13.15%;
    z-index: 2;

    > img {
      width: 100%;
      display: block;

      // Fix for iOS rendering issue with -webkit-overflow-scrolling: touch;.
      transform: translate3d(0, 0, 0);
    }
  }

  &__play-link {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: white;
    z-index: 2;
    width: 40px;
    height: 40px;

    circle {
      transition: fill 0.3s ease-out;
      transition: opacity 0.3s ease-out;
    }
  }

  &--alternative-styling &__progress {
    margin: 4px 8px 0 8px;
  }

  &--default &__cover {
    padding: $watchable-card-height-default 0 0 0;
  }

  &--landscape &__cover {
    padding: $watchable-card-height-landscape 0 0 0;
  }

  &--giant &__cover {
    padding: $watchable-card-height-giant 0 0 0;
  }

  &--cast &__cover {
    padding: $watchable-card-height-cast 0 0 0;
  }

  &--is-loading &__cover-image img {
    opacity: 0;
  }

  &--is-loaded &__cover-image img {
    opacity: 1;
  }

  &--has-error &__cover-image img {
    display: none;
  }

  &--is-selected &__cover-image {
    border: solid 5px $theme-default-button-color;
  }
}


