@import '../../../styles/variables.scss';

.evi-coupons-form {
  padding: 32px 0 0;

  &__heading {
    padding: 0 0 16px;
  }

  &__description {
    margin: 0;
  }

  &__input-row {
    padding: 23px 0 0;
    display: flex;
  }

  &__input {
    padding: 0;
    margin: 0 8px 0 0;
    flex: 1;
    max-width: 180px;
  }

  &__error {
    font-size: 13px;
    line-height: 1.8rem;
    color: $color-error;
    padding: 6px 0 0 0;
  }
}
