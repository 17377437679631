@import '../../styles/mixins';
@import '../../styles/variables';

.evi-card-skeleton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 3px;

  &__cover {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    background-color: var(--neutral100-color);
    border-radius: 2px;
  }

  &__progress-bar {
    width: 100%;
    height: 4px;
    margin: 4px 0 0;
    background-color: var(--neutral100-color);
  }

  &__title, &__secondary-title {
    height: 15px;
    margin: 6px 0 0;
    width: 85%;
    background-color: var(--neutral100-color);

    @include for-phone-large-down {
      height: 14px;
    }
  }

  &:not(&--alternative-styling) &__title {
    margin-top: 11px;
  }

  &__secondary-title {
    width: 50%;
    margin-top: 7px;
  }

  &--default &__cover {
    padding: $watchable-card-height-default 0 0 0;
  }

  &--landscape &__cover {
    padding: $watchable-card-height-landscape 0 0 0;
  }

  &--giant &__cover {
    padding: $watchable-card-height-giant 0 0 0;
  }

  &--event &__cover {
    padding: $watchable-card-height-event 0 0 0;
  }

  &--cast &__cover {
    padding: $watchable-card-height-cast 0 0 0;
  }

  .evi-layout-wide & {
    @include for-desktop-up {
      &__title {
        margin-top: 0.525vw;;
        height: 0.75vw;
      }
    }
  }
}
