@import '../../styles/variables.scss';

.evi-title-details {
  margin: 1.5em 0 0;
  color: var(--neutral600-color);

  & > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
    
    & > span {
      font-weight: 700;
    }
  }

  a {
    color: var(--link-color);
    margin-right: 10px;

    span {
      cursor: pointer;
    }

    &:last-child .comma {
      display: none;
    }
  }
}

.evi-title-details-actors {
  max-height: 36px;
}

.evi-title-details-actors-all {
  max-height: 200px;
  transition: all 0.3s ease;
}

@media only screen and (min-width: $breakpoint-laptop-small-min-width) {
  .evi-title-details {
    max-width: 53%;
  }
}

@media only screen and (min-width: $breakpoint-desktop-min-width) {
  .evi-title-details {
    max-width: 44%;
  }
}
