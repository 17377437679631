.evi-stripe--default,
.evi-grid--default {
  $default-item-width: 190px;
  $default-item-height: 285px;
  $small-phone-item-width: 90px;
  $small-phone-item-height: 135px;
  $phone-item-width: 100px;
  $phone-item-height: 150px;
  $tablet-item-width: 160px;
  $tablet-item-height: 240px;

  .evi-infinite-list {
    margin-right: 0px;
  }

  .slick-list {
    .evi-titlecard {
      padding-left: 5px;
      padding-right: 5px;
      max-width: 100%;
    }
  }

  .slick-list, .evi-infinite-list {
    @include coverImage($default-item-width, $default-item-height);

    @media only screen and (max-width: $breakpoint-tablet-max-width) {
      @include coverImage($tablet-item-width, $tablet-item-height);
    }

    @media only screen and (max-width: $breakpoint-phone-max-width) {
      @include coverImage($phone-item-width, $phone-item-height);
      .evi-titlecard {
        padding: 5px 6px 5px 0;
      }
    }
    @media only screen and (min-width: 301px) and (max-width: 340px) {
      @include coverImage($small-phone-item-width, $small-phone-item-height);
    }
  }

  @media only screen and (min-width: 401px) and (max-width: $breakpoint-phone-large-max-width) {
    .evi-infinite-list {
      .ea-video-thumbnail__description {
        max-width: 120px;
      }
      .evi-titlecard--image, .evi-titlecard-error {
        width: 120px !important;
        height: 180px !important;
      }
    }
  }

  @media only screen and (max-width: 842px) {
    .evi-eventcard {
      padding: 1rem 0.5rem 1rem 0.5rem !important;
    }
    .evi-titlecard-error,
    .evi-eventcard-error {
      margin: 5px 10px 5px 0;
    }
  }
}
