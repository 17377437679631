.evi-scroll-top-button {
  position: fixed;
  z-index: 9;
  right: 3rem;
  bottom: 3rem;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  cursor: pointer;
  background: #006dfc;
  color: white;
  fill: white;
  border-radius: 500px;
  line-height: 0;
  -webkit-transition: 0.25s cubic-bezier(0.82, 0, 0.1, 1);
  transition: 0.25s cubic-bezier(0.82, 0, 0.1, 1);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
}

.evi-scroll-top-button--show {
  -webkit-transform: scaleX(1);
  transform: scale(1, 1);
}
