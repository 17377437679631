@import '../../../styles/mixins.scss';

.evi-coupons {
  &__heading {
    padding: 0 0 21px;
  }

  &__coupon {
    margin-bottom: 4px;

    &:last-child {
      margin: 0;
    }

    .ea-checkbox input+label {
      @include for-phone-large-down {
        font-size: 13px;
      }
    }
  }
}
