@import '../../../styles/mixins';
@import '../../../styles/variables';

.evi-cards-container {
  @include useTheme();
  padding: 30px 0;

  .ea-h2 {
    margin-bottom: 1em;
  }
  .ea-card {
    display: flex;
    height: 100%;
  }
  .ea-card__anchor {
    background: var(--neutral200-color);
    box-shadow: 0 0 10px 0 var(--shadow-color);
    border-radius: 5px;
  }
  .ea-card__content {
    background: linear-gradient(180deg, var(--neutral200-color) 0%, var(--neutral-color) 100%);
    color: var(--neutral700-color);
    border-radius: 0 0 5px 5px;
  }
  .ea-card__content-paragraph {
    flex-grow: 2;
    a {
      color: var(--accent600-color);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ea-fgrid {
    justify-content: center;
  }

  .ea-fgrid--gutter {
    margin: 0 calc(5% - 10px) 20px;

    .ea-fgrid__item {
      padding: 10px;
      margin-top: 10px;
    }
  }
  
  .ea-card__image-header {
    border-radius: 5px;
    overflow: hidden;
    
    &:before {
      padding-top: 42.553191%;
    }
  }
}

.evi-marketing-card {
  .evi-buttons {
    display: flex;
    justify-content: center
  }
  .evi-description {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  .evi-price-container {
     display: flex;
     justify-content: center;
  }
}

// made common class for hbomax, ruutu and viaplay or any other landing page
.product-card .evi-cards-container-eds {
  .ds-card--withbadge .ds-card-heading:not(.ds-card-heading--no-padding) {
    padding-top: 30px;
  }

  .ds-cardcontainer-inner .ds-card-content {
    flex-grow: 0;
  }

  .evi-purchase-button--crm {
    margin-bottom: 0;
  }

  .evi-action-button-group {
    min-height: 165px;
  }
}
