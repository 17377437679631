.evi-show-more {
  &__content {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  &__link {
    border: none;
    background: transparent;
    align-self: flex-end;
    color: var(--link-color);
  }

  &__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &--is-collapsed {
    display: flex;
  }

  &--is-collapsed &__content {
    flex: 1;
  }
}
