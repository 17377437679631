@import '../../../styles/variables';

.evi-marketing-block {
  margin: 0 0;

  ul {
    list-style-type: none;
  }

  &.evi-background-blur {
    background-position-y: center;
  }

  &__article {
    .ea-h2 {
      text-transform: uppercase;
    }
  }
  .ea-button {
    margin-top: 12px;
  }
  .ea-ingresstext {
    margin-bottom: 12px;
    font-style: italic;
  }
  .ea-marketing-block {
    position: relative;
    padding: 42px 0;
  }
  .ea-marketing-block__title {
    text-align: center;
  }
  .ea-marketing-block__aside img {
    border-radius: $default-border-radius;
  }
  @media only screen and (max-width: 959px) {
    .ea-marketing-block__content, .ea-marketing-block__title {
      text-align: center;
    }
  }
  @media only screen and (min-width: 960px) and (max-width: 1399px) {
    .ea-marketing-block__content, .ea-marketing-block__title {
      max-width: 90%;
    }
  }

  &.evi-marketing-block-alternative {
    background-image: none !important;

    .ea-marketing-block__article {
      h2 {
        display: none;
      }

      h3 {
        margin-bottom: 1em;
      }
    }
  }

  &--inside-modal {
    margin: 0 50px 24px;
  }
}

.evi-product-details .evi-marketing-block,
.evi-marketing-block--inside-modal {
  .ea-marketing-block {
    padding: 0;

    &__aside {
      flex: none;
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin: 0 0 16px;
    }

    &__article {
      flex: none;
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin: 0;

      h2, h3, p {
        line-height: 1.2;
        padding: 0;
        margin: 0 0 8px;
      }

      .ea-price-text {
        margin-top: 16px;

        .ea-price-text__price {
          justify-content: center;
        }

        .ea-price-text__discount {
          line-height: 1.6em;
        }
      }

      .ea-disclaimertext {
        text-align: center;
      }

      .ea-pad {
        padding: 0;
        text-align: center;
      }

      .ea-button {
        float: none !important;
        margin: 16px 0 0;
      }
    }
  }
}

.evi-marketing-block--6to4ratio {
  @media only screen and (min-width: 960px) {
    .ea-marketing-block__aside {
      flex: 1 0 60%;
      max-width: 60%;
    }
    .ea-marketing-block__article {
      flex: 1 0 40%;
      max-width: 40%;
    }
  }
  @media only screen and (min-width: 640px) {
    .ea-marketing-block__aside img {
      max-width: 600px;
    }
  }
  @media only screen and (min-width: 960px) {
    .ea-marketing-block__aside img {
      max-width: 720px;
    }
  }
  @media only screen and (min-width: 1400px) {
    .ea-marketing-block__aside img {
      max-width: 720px;
    }
  }
}

