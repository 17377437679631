@import '../../../styles/variables';
@import '../../../styles/mixins';

.evi-payment-method-tabs {
  display: flex;
  margin: 0 -4px;
  padding: 0 0 25px 0;

  &__tab {
    position: relative;
    width: 115px;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid $color-dark-smoke;
    border-radius: 5px;
    color: $color-dark-grey;
    fill: $color-dark-grey;
    font-size: 13px;
    line-height: 15px;
    margin: 0 4px;
    cursor: pointer;

    &:focus-within {
      @include focus-styling-border(3px);
    }

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    &--active {
      border-color: $color-linkblue;
      color: $color-linkblue;
      fill: $color-linkblue;
    }

    &--denied:not(&--active) {
      color: $color-dark-smoke;
      fill: $color-dark-smoke;
    }

    &--active#{&}--denied {
      border-color: $color-error;
      border-width: 2px;
      color: $color-dark-grey;
      fill: $color-dark-grey;
      background-color: transparentize($color-error, .95);
    }

    &-icon {
      display: block;
      margin: 0 auto 9px;
      max-width: 28px;
      max-height: 28px;
      height: 28px;
    }

    &-text {
      display: block;
      cursor: pointer;
    }

    &-checked-icon {
      position: absolute;
      top: 5px;
      left: 5px;
      display: none;
      font-size: 20px;
    }

    &--active:not(&--denied) &-checked-icon {
      display: inline-block !important;
    }
  }
}
