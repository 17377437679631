@import '../../../styles/variables.scss';

.evi-card-reader-devices {
  &__add-card-reader {
    margin-top: 23px;
  }

  &__limit-text {
    color: $color-grey;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
  }

  &__help-text {
    margin-top: 23px;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
}
