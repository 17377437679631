.evi-stripe--giant,
.evi-grid--giant {
  $default-item-width: 320px;
  $default-item-height: 480px;
  $tablet-item-width: 270px;
  $tablet-item-height: 405px;
  $phone-item-width: 150px;
  $phone-item-height: 225px;

  padding: 0;
  .ea-video-thumbnail {
    padding-left: 0;
    padding-right: 10px;
  }

  @include coverImage($default-item-width, $default-item-height);

  @media only screen and (max-width: $breakpoint-phone-max-width) {
    .slick-list {
      @include coverImage($phone-item-width, $phone-item-height);
      .evi-titlecard {
        padding: 5px 6px 5px 0;
      }
      .ea-video-thumbnail__description {
        font-size: 14px;
      }
    }
  }
  @media only screen and (min-width: $breakpoint-phone-large-min-width) and (max-width: $breakpoint-tablet-max-width) {
    .slick-list {
      @include coverImage($tablet-item-width, $tablet-item-height);
    }
  }
}

.evi-stripe--giant {
  .ea-video-thumbnail__description {
    font-size: 16px;
    font-weight: 600;
  }
}