.evi-play-button {
  line-height: 28px;
  display: flex;
  justify-content: center;
  text-shadow: none;
  white-space: nowrap;
  padding-top: 10px;

  .evi-spinner {
    border-color: #fff #fff transparent #fff !important;
  }

  .ea-icon {
    font-size: 28px;
    padding-right: 8px;
    filter: none;
  }
}
