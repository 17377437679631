.evi-product-details {
  a {
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
  .evi-marketing-block {
    background-image: none !important;
    background-color: transparent !important;
  }

  .evi-text-block {
    margin-bottom: 40px;
  }
}
