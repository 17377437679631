@import '../../../../styles/variables';

.evi-stb-item {
  display: flex;
  border: 2px solid $color-dark-grey;
  padding: 6px 12px;
  border-radius: 2px;

  &__icon {
    padding: 0 16px 0 0;

    img {
      color: $color-linkblue;
      width: 32px;
      font-size: 24px;
    }
  }

  &__content {
    flex: 1;
    text-align: left;
  }

  &__model {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &__mac {
    font-size: 13px;
    line-height: 19px;
  }
}
