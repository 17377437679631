.evv-product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px 35px;
  flex-grow: 1;

  .ea-h3 {
    min-height: 60px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__description {
    *:not(strong) {
      font-weight: 400;
      line-height: 1.4;
      margin-top: 0;
    }
  }

  .ea-price-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .ea-price-text__price {
    justify-content: center;
  }

  .ea-price-text__unit {
    font-weight: 600;
  }

  .ea-price-text__discount {
    font-size: 16px;
    line-height: 1.5;
  }

  .ea-disclaimertext {
    max-width: 258px;
    margin-top: 8px;
    margin-bottom: 6px;
    
    *:not(strong) {
      font-weight: 400;
      line-height: 1.4;
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  .ea-button {
    margin-top: auto;
  }
}