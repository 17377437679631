@use "sass:map";
@import "~@design-system/component-library/src/globals/scss/_variables.scss";

// Heading
#viihdeonline-react-app-container h2 {
  &.ds-h1 {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
  }
  
  @media screen and (min-width: 480px) {
    &.ds-h1 {
      font-size: calc(32px + 24 * (100vmin - 480px) / 920);
    }
  }
  
  @media screen and (min-width: 1400px) {
    &.ds-h1 {
      font-size: 56px;
    }
  }
  
  @media screen and (min-width: 480px) {
    &.ds-h1 {
      line-height: calc(36px + 28 * (100vmin - 480px) / 920);
    }
  }
  
  @media screen and (min-width: 1400px) {
    &.ds-h1 {
      line-height: 64px;
    }
  }
}
// Heading end

// Hero Component
.overrides {
  .ds-hero__background--white {
      background-color: var(--neutral-color);
  }
  .eds-hero-theme-logo {
    height: auto;
    position: absolute;
    right: -63.4vw;
    top: -5vw;
    width: 8vw;
    z-index: 2;
    max-height: 32px;
  }
}

@media (min-width: $breakpoint-m) {
  .overrides {
    .ds-hero__content {
        h1 {
          margin-bottom: 24px !important;
          margin-block-start: 0;
          margin-block-end: 0;
        }
    
        p {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-bottom: 24px;
        }
  
        .evi-hero-buttons {
          margin-top: 2.5rem;
        }
      }
  }
  
}

@media (max-width: $breakpoint-l) {
  .overrides {
    .ds-hero__mobilecontent--below .ds-hero__gradient {
      right: 10%;
    }
  }
}
// Hero Component end

// Accordion Component
.overrides.collapsible-text-block:nth-last-child(1) {
  margin-bottom: 64px;
}
.overrides {
  .accordion_product-theme-header {
    text-align: left;
    margin: 0 0 20px 0;
  }
  .ds-accordion__section__content {
    line-height: 24px;
  }
}
.evi-modal__content, .evi-product-additional-info-wrapper {
  .overrides {
    .accordion {
      .ds-grid__row {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: $breakpoint-xl) {
  .overrides {
    .accordion {
      .ds-grid__row {
        width: 100%;
      }
    }
  }
}
@media (max-width: $breakpoint-s) {
  .overrides {
    .accordion {
      .ds-grid__row {
        display: block;
      }
    }
  }
}
// Accordion Component end

// Card component
.ds-card {
  .ds-card-heading {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
@media (max-width: map.get($grid-breakpoint-max-width, "xs")) {
  #viihdeonline-react-app-container .overrides {
    &.ds-card {
      max-width: 95%;
    }
  }
}
// Card component end
