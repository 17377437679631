.evi-event-vertical-list {
  &--horizontal-margin {
    margin: 0 5%;
  }
  .evi-stripe-heading {
    padding: 0!important;
  }
  .evi-eventcard-list-date {
    overflow: visible;
  }
}
